// import React, { useState, useEffect } from 'react';
// import { Document, Page as PDFPage, pdfjs } from 'react-pdf';
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// import { useParams } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
// import energyOfferService from '../../services/energyOfferService';
// import Page from '../../components/layoutParts/Page';

// const EnergyOfferDetail = () => {
//     const { t } = useTranslation();
//     const { id } = useParams();
//     const [energyOffer, setEnergyOffer] = useState({});

//     useEffect(() => {
//         getEnergyOffer();
//     }, []);

//     const getEnergyOffer = async () => {
//         const { data } = await energyOfferService.getEnergyOffer(id);
//         setEnergyOffer(data);
//     };

//     console.log(energyOffer)

//     if(Object.keys(energyOffer).length === 0) {
//         return <p>Loading</p>
//     }

//     return (
//         <Page title='contracts'>
//             <Document file={energyOffer.url}>

//             </Document>
//         </Page>
//     )
// }

// export default EnergyOfferDetail;

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import energyOfferService from '../../services/energyOfferService';
import Page from '../../components/layoutParts/Page';
import ViewSDKClient from '../../utils/viewSDKClient';
import MessageInfoCard from '../../components/cards/MessageInfoCard';
import Button from '../../components/general/Button';
import ActionInfoCard from '../../components/cards/ActionInfoCard';
import { InformationCircleIcon } from '@heroicons/react/outline';

const EnergyOfferDetail = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [energyOffer, setEnergyOffer] = useState({});

    useEffect(() => {
        getEnergyOffer();
    }, []);

    const getEnergyOffer = async () => {
        const { data } = await energyOfferService.getEnergyOffer(id);
        setEnergyOffer(data);
    };

    const acceptOffer = async (id) => {
        const response = await energyOfferService.acceptEnergyOffer(id);
        console.log(response);
    };

    const getEnergyType = (type) => {
        switch (type) {
            case 'E':
                return 'electricity';
            case 'G':
                return 'gas';
            case 'M':
                return 'multi';
        }
    };

    useEffect(() => {
        if (energyOffer.url) {
            const viewSDKClient = new ViewSDKClient();
            viewSDKClient.ready().then(() => {
                viewSDKClient.previewFile(
                    'pdf-div',
                    {
                        showAnnotationTools: false,
                        showLeftHandPanel: false,
                        showPageControls: false,
                        showDownloadPDF: true,
                        showPrintPDF: true,
                        showFullScreen: true,
                        defaultViewMode: 'FIT_WIDTH',
                    },
                    {
                        id: energyOffer.id,
                        fileName: `Offer #${energyOffer.reference}`,
                        hasReadOnlyAccess: true,
                    },
                    energyOffer.url,
                );
            });
        }
    }, [energyOffer]);

    return (
        <Page title={`Offer ${energyOffer.reference ? `#${energyOffer.reference}` : 'details'}`}>
            <MessageInfoCard>
                <h2 className='text-lg font-medium text-gray-900'>{t('ask_question')}</h2>
                <p className='text-base font-light text-gray-700'>{t('platform_offer_action_description_opt_out')}</p>
                <Button layout='link' label='accept_offer' onClick={() => acceptOffer(energyOffer.id)} />
            </MessageInfoCard>
            <div className='mt-6 grid grid-cols-12 gap-x-10 gap-y-4 h-full'>
                <div className='col-span-12 2xl:col-span-8 h-full'>
                    <div id='pdf-div' className='w-full h-full' />
                </div>

                <div className='col-span-12 2xl:col-span-4 space-y-6'>
                    <h2 className='text-lg font-medium leading-6 text-gray-900'>Details</h2>

                    <dl className='grid grid-cols-1 gap-x-4 gap-y-8'>
                        <div className='col-span-1'>
                            <dt className='text-sm font-medium text-gray-500'>{t('offer_ref')}</dt>
                            <dd className='mt-1 text-sm text-gray-900'>#{energyOffer.reference}</dd>
                        </div>
                        <div className='col-span-1'>
                            <dt className='text-sm font-medium text-gray-500'>{t('energy_type')}</dt>
                            <dd className='mt-1 text-sm text-gray-900'>{t(getEnergyType(energyOffer.energy_type))}</dd>
                        </div>
                        <div className='col-span-1'>
                            <dt className='text-sm font-medium text-gray-500'>{t('energy_supplier')}</dt>
                            <dd className='mt-1 text-sm text-gray-900'>{energyOffer.supplier}</dd>
                        </div>
                        <div className='col-span-1'>
                            <dt className='text-sm font-medium text-gray-500'>{t('offer_valid_duration')}</dt>
                            <dd className='mt-1 text-sm text-gray-900'>
                                {moment(energyOffer.offer_valid_from).format('DD/MM/YYYY')} - {moment(energyOffer.offer_valid_till).format('DD/MM/YYYY')}
                            </dd>
                        </div>
                        <div className='col-span-1'>
                            <dt className='text-sm font-medium text-gray-500'>{t('contract_valid_duration')}</dt>
                            <dd className='mt-1 text-sm text-gray-900'>
                                {moment(energyOffer.offer_start_date).format('DD/MM/YYYY')} - {moment(energyOffer.offer_end_date).format('DD/MM/YYYY')}
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
        </Page>
    );
};

export default EnergyOfferDetail;
