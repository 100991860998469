import React, { useContext } from 'react';
import Icon from '@mdi/react';
import { mdiPower } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../context/AuthContext';
import usePageTitle from '../../hooks/usePageTitle';

const Page = ({ title, content, children }) => {
    const { t } = useTranslation();
    usePageTitle(t(title));
    const authContext = useContext(AuthContext);

    return (
        <>
            <header className='bg-gaele-light-grey'>
                <div className='flex justify-between'>
                    <div className='flex flex-1 items-center justify-between px-4 py-1.5 h-16 lg:h-24 lg:px-8'>
                        <div>
                            <h1 className='text-lg lg:text-2xl font-medium'>{t(title)}</h1>
                        </div>
                        {content && <div>{content}</div>}
                    </div>

                    <button onClick={authContext.logout} className='hidden lg:flex items-center text-slate-400 p-8 space-x-4 hover:bg-black/5 border-l border-black/10'>
                        <span className='text-lg font-medium'>{t('logout')}</span>
                        <Icon path={mdiPower} size={1} />
                    </button>
                </div>
            </header>
            <main className='p-4 lg:p-8 flex flex-col flex-1'>{children}</main>
        </>
    );
};

export default Page;
