import apiClient from '../utils/apiClient';

export default {
    requestVerificationToken() {
        return apiClient.get('/mandates/request-token');
    },
    verifyMandate(data) {
        return apiClient.post('/mandates/verify', data);
    },
};
