import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import useForm from '../../hooks/useForm';
import { AuthContext } from '../../context/AuthContext';
import { NotificationContext } from '../../context/NotificationContext';

import { verifyMandateValidationSchema } from '../../validators/mandate';

import CodeInput from '../../components/formElements/CodeInput';
import SubmitButton from '../../components/formElements/SubmitButton';
import mandateService from '../../services/mandateService';
import Page from '../../components/layoutParts/Page';
import Card from '../../components/cards/BaseCard';

const initialValues = {
    mandate_verification_code: '',
};

const SignMandate = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [phone, setPhone] = useState('');
    const [searchParams] = useSearchParams();
    const authContext = useContext(AuthContext);
    const notificationContext = useContext(NotificationContext);

    const { formData, errors, handleInputChange, handleSubmit } = useForm({
        initialValues: initialValues,
        validationSchema: verifyMandateValidationSchema,
    });

    useEffect(() => {
        requestMandateVerificationToken();
    }, []);

    const requestMandateVerificationToken = async () => {
        const { data } = await mandateService.requestVerificationToken();
        setPhone(data.phone);
    };

    useEffect(() => {
        if (formData.mandate_verification_code.length === 6) {
            handleSubmit(submit);
        }
    }, [formData.mandate_verification_code]);

    const submit = async (values) => {
        console.log(values);
        const { data } = await mandateService.verifyMandate({
            verification_token: values.mandate_verification_code,
        });
        console.log(data);

        notificationContext.addNotification('success', 'mandate_verified', 'mandate_verified_description');
        navigate('/onboarding');
    };

    return (
        <Page title='sign_mandate'>
            <Card containerClasses='w-full sm:max-w-md' contentClasses='space-y-6'>
                <div className='space-y-4'>
                    <h2 className='text-lg font-medium text-gray-900'>{t('confirm_mandate')}</h2>
                    <p className='text-base font-light text-gray-700'>
                        <Trans i18nKey='confirm_mandate_description' values={{ phone }}>
                            We sent a 6-digit code to <span className='font-medium'>{phone}</span>. Please enter this code here to confirm your mandate.
                        </Trans>
                    </p>
                </div>

                <form onSubmit={handleSubmit(submit)} className='space-y-6'>
                    <CodeInput label='confirmation_code' id='mandate_verification_code' name='mandate_verification_code' value={formData.mandate_verification_code} allowedCharacters='numeric' error={errors.mandate_verification_code} onChange={handleInputChange} />
                    <SubmitButton label='sign_mandate' />
                    {/* <p className='text-sm font-light text-gray-800 text-center'>(Resend code in {counter}s)</p> */}
                </form>
            </Card>
        </Page>
    );
};

export default SignMandate;
