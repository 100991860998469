import React from 'react';
import Icon from '@mdi/react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { mdiLightbulb } from '@mdi/js';
import Page from '../../components/layoutParts/Page';
import Card from './../../components/cards/BaseCard';
import MessageInfoCard from '../../components/cards/MessageInfoCard';
import Button from '../../components/general/Button';

const ContactUs = () => {
    const { t } = useTranslation();

    return (
        <Page title='contact_us'>
            <Card contentClasses='space-y-6'>
                <div className='space-y-6'>
                    <h2 className='text-lg leading-6 text-gray-900'>{t('contact_information')}</h2>
                    <dl className='text-base space-y-4 lg:flex lg:space-y-0 lg:space-x-6'>
                        <div>
                            <dt className='text-sm font-medium'>{t('phone')}</dt>
                            <dd className='text-md'>{t('platform_phone')}</dd>
                        </div>
                        <div>
                            <dt className='text-sm font-medium'>{t('address')}</dt>
                            <dd className='text-md'>
                                {t('platform_street')} {t('platform_house_number')}, {t('platform_postal_code')} {t('platform_city')}
                            </dd>
                        </div>
                    </dl>
                </div>

                <div className='space-y-6'>
                    <h2 className='text-lg leading-6 text-gray-900'>{t('ask_question')}</h2>

                    <MessageInfoCard>
                        <h2 className='text-lg font-medium text-gray-900'>{t('ask_question')}</h2>
                        <p className='text-base font-light text-gray-700'>{t('ask_question_description')}</p>
                    </MessageInfoCard>

                    <div className='flex'>
                        <Button color='orange' to='/tickets?ask_question=true' label='ask_new_question' />
                    </div>
                </div>
            </Card>
        </Page>
    );
};

export default ContactUs;
