import * as yup from 'yup';

yup.addMethod(yup.string, 'EAN', function (errorMessage) {
    return this.test(`test-ean-number`, errorMessage, function (eanNumber) {
        const { path, createError } = this;

        let total = 0;

        if (eanNumber.length !== 18) {
            return createError({ path, message: errorMessage });
        }

        if (!eanNumber.startsWith('54')) {
            return createError({ path, message: errorMessage });
        }

        for (let i = 0; i < eanNumber.length - 1; i += 1) {
            if (i % 2) {
                total += parseInt(eanNumber.charAt(i));
            } else {
                total += parseInt(eanNumber.charAt(i)) * 3;
            }
        }

        const nextTenFold = Math.ceil(total / 10) * 10;
        const lastNumber = parseInt(eanNumber.slice(-1));

        if (nextTenFold - total !== lastNumber) {
            return createError({ path, message: errorMessage });
        }

        return true || createError({ path, message: errorMessage });
    });
});

export const addEanValidationSchema = yup.object().shape({
    ean_type: yup.string().required('required_field'),
    ean_code: yup.string().required('required_field').EAN('invalid_ean'),
});

export const amountOfEansValidationSchema = yup.object().shape({
    number_of_electricity_eans: yup.mixed().required('required_field').oneOf([0, 1, 2, 3, 4, 5, '5+']),
    number_of_gas_eans: yup.mixed().required('required_field').oneOf([0, 1, 2, 3, 4, 5, '5+']),
});
