import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiFileCheck, mdiDownload, mdiDelete } from '@mdi/js';

import Page from '../../components/layoutParts/Page';
import Button from '../../components/general/Button';
import eanService from '../../services/eanService';
import EanInfoCard from '../../components/cards/EanInfoCard';
import MessageInfoCard from '../../components/cards/MessageInfoCard';
import Card from '../../components/cards/BaseCard';
import invoiceService from '../../services/invoiceService';
import { NotificationContext } from '../../context/NotificationContext';
import FileUploadButton from '../../components/formElements/FileUploadButton';
import useForm from '../../hooks/useForm';
import SubmitButton from '../../components/formElements/SubmitButton';

const initialValues = {
    invoices: [],
};

const Overview = () => {
    const { t } = useTranslation();
    const [eans, setEans] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const notificationContext = useContext(NotificationContext);

    const { formData, errors, handleInputChange, handleSubmit } = useForm({
        initialValues: initialValues,
    });

    useEffect(() => {
        getEans();
    }, []);

    const getEans = async () => {
        const response = await eanService.getEans();
        console.log(response);
        setEans(response.data.results);
    };

    const deleteEan = async (id) => {
        await eanService.deleteEan(id);
        setEans((eans) => eans.filter((ean) => ean._id !== id));
        notificationContext.addNotification('success', 'ean_deleted', 'ean_deleted_description');
    };

    const uploadInvoices = async ({ invoices }) => {
        await invoiceService.uploadInvoices(invoices);
    };

    return (
        <Page
            title='eans'
            content={
                <div className='flex gap-x-6'>
                    <Button color='gas' to='/eans/add?type=G' label='add_gas_ean' />
                    <Button color='electricity' to='/eans/add?type=E' label='add_electricity_ean' />
                </div>
            }
        >
            <section className='space-y-6'>
                {/* <header>
                    <h2 className='text-2xl font-medium'>{t('eans')}</h2>
                </header> */}

                <div className='grid grid-cols-12 gap-x-10 gap-y-4'>
                    {eans.map((ean, i) => (
                        <div className='col-span-12 max-w-lg 2xl:col-span-6 2xl:max-w-none' key={i}>
                            <EanInfoCard {...ean} deleteEan={deleteEan} />
                        </div>
                    ))}
                </div>
            </section>

            {/* <section className='space-y-6'>
                <MessageInfoCard>
                    <div className='flex justify-between'>
                        <div>
                            <h2 className='text-lg font-medium text-gray-900'>{t('upload_invoice_tooltip_title')}</h2>
                            <p className='text-base font-light text-gray-700'>{t('upload_invoice_tooltip_subtitle')}</p>
                        </div>
                        <form onSubmit={handleSubmit(uploadInvoices)}>
                            <div className='space-y-2'>
                                <FileUploadButton name='invoices' multiple={true} onChange={handleInputChange} />
                                <SubmitButton disabled={formData.invoices.length === 0} label='send_invoices' />
                            </div>
                        </form>
                    </div>
                </MessageInfoCard>
            </section> */}
        </Page>
    );
};

export default Overview;
