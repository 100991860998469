import React from 'react';
import { useTranslation } from 'react-i18next';

const Checkbox = ({ label, id, name, value = false, error, onChange, containerClasses }) => {
    const { t } = useTranslation();

    return (
        <div className={`flex space-x-4 ${containerClasses}`}>
            <label htmlFor={id} className='flex select-none'>
                <input className='mt-1 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded' type='checkbox' id={id} name={name} checked={value} onChange={onChange} />
                <span className='inline-block ml-2 select-none'>{t(label)}</span>
            </label>

            {error && <span className='mt-2 text-sm text-red-600'>{t(error)}</span>}
        </div>
    );
};

export default Checkbox;
