import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { mdiMenu } from '@mdi/js';
import Icon from '@mdi/react';
import { AuthContext } from '../../context/AuthContext';
import Logo from './../../assets/logo.svg';

const MobileHeader = ({ setSidebarOpen }) => {
    const { t } = useTranslation();
    const authContext = useContext(AuthContext);

    return (
        <div className='lg:hidden'>
            <div className='flex items-center justify-between bg-gradient-to-b from-gaele-light-blue to-gaele-dark-blue h-16 px-4 py-1.5'>
                <div>
                    <img className='h-8 w-auto' src={Logo} alt='Gaele logo' />
                </div>
                <div>
                    {authContext.isAuthenticated ? (
                        <button type='button' onClick={() => setSidebarOpen(true)} className='-mr-2 h-10 w-10 inline-flex items-center justify-center rounded-full text-white hover:text-white-900 hover:bg-black/10'>
                            <span className='sr-only'>Open sidebar</span>
                            <Icon path={mdiMenu} size={1} color='white' aria-hidden='true' />
                        </button>
                    ) : (
                        <span className='text-white font-light text-sm'>{t('platform_tagline')}</span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MobileHeader;
