import React from 'react';
import { useTranslation } from 'react-i18next';

const Input = ({ type = 'text', containerClasses, label, id, name, disabled, placeholder, trailing, value = '', error, onChange }) => {
    const { t } = useTranslation();

    return (
        <div className={containerClasses}>
            {label && (
                <label htmlFor={id} className='block text-sm font-light tracking-wider text-gray-700'>
                    {t(label)}
                </label>
            )}

            <div className={`relative w-full mt-1 border-b ${disabled ? 'border-transparent' : 'border-gray-300'} focus-within:border-indigo-600`}>
                <input disabled={disabled} className='block w-full border-0 border-b border-transparent bg-gray-50 placeholder:text-slate-400 disabled:bg-slate-50 disabled:text-slate-400 disabled:border-dashed disabled:border-slate-200 disabled:shadow-none focus:border-indigo-600 focus:ring-0 sm:text-sm' type={type} id={id} name={name} placeholder={t(placeholder)} value={value} onChange={onChange} autoComplete='off' />

                {trailing && (
                    <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
                        <span className='text-gray-500 sm:text-sm' id='price-currency'>
                            {t(trailing)}
                        </span>
                    </div>
                )}
            </div>
            {error && <span className='mt-2 text-sm text-red-600'>{t(error)}</span>}
        </div>
    );
};

export default Input;
