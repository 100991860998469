import React from 'react';
import { Link } from 'react-router-dom';
import NotFoundGirl from './../../assets/not-found-girl.png';

const NotFound = () => {
    return (
        <main className='w-screen h-screen p-4 flex justify-center items-center'>
            <div className='space-y-4'>
                <div>
                    <h1 className='text-lg lg:text-2xl font-medium'>Oops!</h1>
                    <h2>We couldn&apos;t find the requested page</h2>
                </div>
                <Link to='/' className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-10 py-2 bg-gaele-light-blue text-base font-medium text-white hover:bg-gaele-dark-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto'>
                    Return to homepage
                </Link>
            </div>
        </main>
    );
};

export default NotFound;
