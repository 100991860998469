import React, { Fragment, useState, useContext, useEffect } from 'react';
import Icon from '@mdi/react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';
import { mdiAccount, mdiFileChart, mdiForum, mdiFileCheck, mdiFacebook, mdiPhone, mdiArrowLeft, mdiArrowRight, mdiClose, mdiPower } from '@mdi/js';
import { AuthContext } from '../../context/AuthContext';
import Logo from './../../assets/logo.svg';
import Girl from './../../assets/girl.png';

const AppSidebar = ({ sidebarOpen, setSidebarOpen, disabled }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const { logout } = useContext(AuthContext);
    const [expandSidebar, setExpandSidebar] = useState(true);

    const navigation = [
        { label: 'my_profile', icon: mdiAccount, href: '/profile' },
        { label: 'my_eans', icon: mdiFileChart, href: '/eans' },
        { label: 'ask_question', icon: mdiForum, href: '/tickets' },
        { label: 'contracts', icon: mdiFileCheck, href: '/contracts' },
    ];

    const classNames = (...classes) => {
        return classes.filter(Boolean).join(' ');
    };

    useEffect(() => {
        setSidebarOpen(false);
    }, [location]);

    return (
        <>
            {/* Mobile sidebar */}
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as='div' className='relative z-40 lg:hidden' onClose={() => setSidebarOpen(false)}>
                    <Transition.Child as={Fragment} enter='transition-opacity ease-linear duration-300' enterFrom='opacity-0' enterTo='opacity-100' leave='transition-opacity ease-linear duration-300' leaveFrom='opacity-100' leaveTo='opacity-0'>
                        <div className='fixed inset-0 bg-gray-600 bg-opacity-75' />
                    </Transition.Child>

                    <div className='fixed inset-0 flex z-40'>
                        <Transition.Child as={Fragment} enter='transition ease-in-out duration-300 transform' enterFrom='-translate-x-full' enterTo='translate-x-0' leave='transition ease-in-out duration-300 transform' leaveFrom='translate-x-0' leaveTo='-translate-x-full'>
                            <Dialog.Panel className='relative flex-1 flex flex-col justify-between max-w-xs w-full pt-5 pb-4 bg-gradient-to-b from-gaele-light-blue to-gaele-dark-blue'>
                                <div className='flex-shrink-0 flex justify-between items-center mx-6'>
                                    <img className='h-8 w-auto' src={Logo} alt='Gaele logo' />
                                    <button type='button' className='-mr-3 flex items-center justify-center h-10 w-10 rounded-full text-white hover:bg-black/10' onClick={() => setSidebarOpen(false)}>
                                        <span className='sr-only'>Close sidebar</span>
                                        <Icon path={mdiClose} size={1} />
                                    </button>
                                </div>
                                <div className='mt-5 flex-1 h-0 overflow-y-auto'>
                                    {!disabled && (
                                        <div className='space-y-6'>
                                            <nav className='space-y-1'>
                                                {navigation.map((item) => (
                                                    <NavLink key={item.label} to={item.href} className={({ isActive }) => `${isActive ? 'border-white bg-black/5' : 'border-transparent'} px-10 text-white border-l-4 flex space-x-4 py-8 hover:bg-black/5`}>
                                                        <Icon path={item.icon} size={1} />
                                                        <span className='font-medium text-lg'>{t(item.label)}</span>
                                                    </NavLink>
                                                ))}
                                            </nav>

                                            <div className='px-4 space-x-10 mx-6 flex bg-black/20 rounded-lg justify-center py-4'>
                                                <a href='https://www.facebook.com/gaele.be' target='_blank' rel='noreferrer'>
                                                    <Icon path={mdiFacebook} size={1} color='white' />
                                                </a>
                                                <Link to='/contact-us'>
                                                    <Icon path={mdiPhone} size={1} color='white' />
                                                </Link>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className='flex-shrink-0 flex justify-center p-4'>
                                    <button onClick={logout} className='flex items-center justify-center text-slate-400 hover:text-white space-x-4'>
                                        <Icon path={mdiPower} size={1} />
                                        <span className='text-lg font-medium'>{t('logout')}</span>
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Desktop sidebar */}
            <aside className={classNames(expandSidebar ? 'w-80' : 'w-20', 'hidden lg:block min-h-full h-full relative')}>
                <div className='h-full py-14 space-y-6 bg-gradient-to-b from-gaele-light-blue to-gaele-dark-blue'>
                    <div className={classNames(expandSidebar ? 'justify-end' : 'justify-center', 'flex mx-6')}>
                        <button className='cursor-pointer rounded-full p-2 hover:bg-black/10' onClick={() => setExpandSidebar(!expandSidebar)}>
                            <Icon path={expandSidebar ? mdiArrowLeft : mdiArrowRight} size={1} color='white' />
                        </button>
                    </div>

                    {expandSidebar && (
                        <div className='space-y-6'>
                            <div className='flex justify-center'>
                                <img src={Logo} alt='Gaele logo' className='w-1/2' />
                            </div>
                            <div>
                                <h1 className='font-semibold text-white text-xl text-center px-12'>{t('platform_tagline')}</h1>
                            </div>
                        </div>
                    )}

                    {!disabled && (
                        <>
                            <div>
                                <div className='flex-1 flex flex-col pt-5 pb-4'>
                                    <ul>
                                        {navigation.map((item, i) => (
                                            <li key={i}>
                                                <NavLink to={item.href} className={({ isActive }) => `${isActive ? 'border-white bg-black/5' : 'border-transparent'} ${expandSidebar ? 'px-10' : 'px-4 justify-center'} text-white border-l-4 flex space-x-4 py-8 hover:bg-black/5`}>
                                                    <Icon path={item.icon} size={1} />
                                                    {expandSidebar && <span className='font-medium text-lg'>{t(item.label)}</span>}
                                                </NavLink>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            <div className={`${expandSidebar ? 'px-4 space-x-10 mx-6' : 'flex-col items-center space-y-6 mx-3'} flex bg-black/20 rounded-lg justify-center py-4`}>
                                <a href='https://www.facebook.com/gaele.be' target='_blank' rel='noreferrer'>
                                    <Icon path={mdiFacebook} size={1} color='white' />
                                </a>
                                <Link to='/contact-us'>
                                    <Icon path={mdiPhone} size={1} color='white' />
                                </Link>
                            </div>
                        </>
                    )}
                </div>
            </aside>
        </>
    );
};

export default AppSidebar;
