import React from 'react';
import { useTranslation } from 'react-i18next';

const Textarea = ({ type = 'text', containerClasses, label, id, name, disabled, placeholder, trailing, value = '', error, onChange }) => {
    const { t } = useTranslation();

    return (
        <div className={containerClasses}>
            {label && (
                <label htmlFor={id} className='block text-sm font-light tracking-wider text-gray-700'>
                    {t(label)}
                </label>
            )}
            <div className={`relative w-full mt-1 border-b ${disabled ? 'border-transparent' : 'border-gray-300'} focus-within:border-indigo-600`}>
                <textarea
                    disabled={disabled}
                    className='block w-full min-h-24 border-0 border-b border-transparent bg-gray-50 disabled:bg-slate-50 disabled:text-slate-400 disabled:border-dashed disabled:border-slate-200 disabled:shadow-none focus:border-indigo-600 focus:ring-0 sm:text-sm'
                    type={type}
                    id={id}
                    name={name}
                    placeholder={t(placeholder)}
                    value={value}
                    onChange={onChange}
                    autoComplete='off'
                />
            </div>
            {error && <span className='mt-2 text-sm text-red-600'>{t(error)}</span>}
        </div>
    );
};

export default Textarea;
