import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const usePageTitle = (title) => {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = `${t('platform_name')} | ${title}`;
    }, [title]);

    return null;
};

export default usePageTitle;
