import axios from 'axios';
// import { useContext } from 'react';
// import { NotificationContext } from '../context/NotificationContext';

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    withCredentials: true,
    timeout: 10000,
});

apiClient.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

apiClient.interceptors.response.use(
    (response) => {
        return Promise.resolve(response);
    },
    (error) => {
        if (!error.response) {
            // network error
            // console.log('Network error');
            // How to access AddNotification here
        } else {
            const response = error.response.data;
            // How to access AddNotification here
        }

        return Promise.reject(error);
    },
);

export default apiClient;
