import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Page from '../../components/layoutParts/Page';
import Card from '../../components/cards/BaseCard';

import useForm from '../../hooks/useForm';

import { NotificationContext } from '../../context/NotificationContext';

import Intro from './steps/Intro';
import SelectEans from './steps/SelectEans';
import AddElectricityEan from './steps/AddElectricityEan';
import AddGasEan from './steps/AddGasEan';
import Finish from './steps/Finish';
import eanService from '../../services/eanService';

import { addEanValidationSchema } from '../../validators/ean';
import { mandateOptionValidationSchema } from '../../validators/mandate';

const initialValues = {
    number_of_electricity_eans: 1,
    number_of_gas_eans: 1,
};

const Overview = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const notificationContext = useContext(NotificationContext);
    const [steps, setSteps] = useState([]);
    const [currentStep, setCurrentStep] = useState(1);
    const [energySuppliers, setEnergySuppliers] = useState([]);

    const [amountOfElectricityEans, setAmountOfElectricityEans] = useState(1);
    const [amountOfGasEans, setAmountOfGasEans] = useState(1);

    const { formData, errors, handleInputChange, handleSubmit, reset, setValidationSchema } = useForm({
        initialValues,
    });

    const generateSteps = () => {
        let steps = [
            { id: 1, label: 'intro', component: 'intro' },
            { id: 2, label: 'select_number_of_eans', component: 'select_eans' },
        ];

        for (let i = 1; i <= amountOfElectricityEans; i++) {
            steps.push({ id: steps.length + 1, label: 'add_electricity_ean', component: 'add_electricity_ean' });
        }

        for (let i = 1; i <= amountOfGasEans; i++) {
            steps.push({ id: steps.length + 1, label: 'add_gas_ean', component: 'add_gas_ean' });
        }

        steps.push({ id: steps.length + 1, label: 'finish', component: 'finish' });

        setSteps(steps);
    };

    useEffect(() => {
        generateSteps();
    }, [amountOfElectricityEans, amountOfGasEans]);

    useEffect(() => {
        getEnergySuppliers();
    }, []);

    const getEnergySuppliers = async () => {
        const { data } = await eanService.getEnergySuppliers();
        setEnergySuppliers(data.energy_suppliers);
    };

    const submit = async (values) => {
        const { component } = steps.find((s) => s.id === currentStep);

        if (component === 'intro') {
            setCurrentStep(currentStep + 1);
        } else if (component === 'select_eans') {
            setAmountOfElectricityEans(formData.number_of_electricity_eans);
            setAmountOfGasEans(formData.number_of_gas_eans);
            setCurrentStep(currentStep + 1);
            reset({
                ean_type: 'E',
                ean_code: '5414',
                has_solar_installation: false,
            });
            setValidationSchema(addEanValidationSchema);
        } else if (component === 'add_electricity_ean') {
            await eanService.createEan(values);

            const { component: nextComponent } = steps.find((s) => s.id === currentStep + 1);

            setCurrentStep(currentStep + 1);

            reset({
                ean_type: nextComponent === 'add_electricity_ean' ? 'E' : 'G',
                ean_code: '5414',
                has_solar_installation: false,
            });

            setValidationSchema(addEanValidationSchema);
        } else if (component === 'add_gas_ean') {
            await eanService.createEan(values);

            const { component: nextComponent } = steps.find((s) => s.id === currentStep + 1);

            setCurrentStep(currentStep + 1);

            if (nextComponent === 'finish') {
                reset({
                    energy_mandate: 'auto',
                });

                setValidationSchema(mandateOptionValidationSchema);
            } else {
                reset({
                    ean_type: 'G',
                    ean_code: '5414',
                    has_solar_installation: false,
                });

                setValidationSchema(addEanValidationSchema);
            }
        } else if (component === 'finish') {
            notificationContext.addNotification('success', 'profile_updated', 'profile_updated_description');
            navigate('/profile');
        }
    };

    const nextStep = () => {
        const { component } = steps.find((s) => s.id === currentStep);

        if (component === 'intro') {
            setCurrentStep(currentStep + 1);
        } else if (component === 'select_eans') {
            setAmountOfElectricityEans(formData.number_of_electricity_eans);
            setAmountOfGasEans(formData.number_of_gas_eans);
            setCurrentStep(currentStep + 1);
            reset({
                ean_type: 'E',
                ean_code: '5414',
                has_solar_installation: false,
            });
            setValidationSchema(addEanValidationSchema);
        } else if (component === 'add_electricity_ean') {
            const { component: nextComponent } = steps.find((s) => s.id === currentStep + 1);

            setCurrentStep(currentStep + 1);

            reset({
                ean_type: nextComponent === 'add_electricity_ean' ? 'E' : 'G',
                ean_code: '5414',
                has_solar_installation: false,
            });

            setValidationSchema(addEanValidationSchema);
        } else if (component === 'add_gas_ean') {
            const { component: nextComponent } = steps.find((s) => s.id === currentStep + 1);

            setCurrentStep(currentStep + 1);

            if (nextComponent === 'finish') {
                reset({
                    energy_mandate: 'auto',
                });

                setValidationSchema(mandateOptionValidationSchema);
            } else {
                reset({
                    ean_type: 'G',
                    ean_code: '5414',
                    has_solar_installation: false,
                });

                setValidationSchema(addEanValidationSchema);
            }
        } else if (component === 'finish') {
            notificationContext.addNotification('success', 'profile_updated', 'profile_updated_description');
            navigate('/profile');
        }
    };

    const renderStep = ({ id, component }) => {
        switch (component) {
            case 'intro':
                return <Intro step={id} currentStep={currentStep} />;
            case 'select_eans':
                return <SelectEans step={id} currentStep={currentStep} formData={formData} errors={errors} handleInputChange={handleInputChange} />;
            case 'add_electricity_ean':
                return <AddElectricityEan step={id} currentStep={currentStep} nextStep={nextStep} energySuppliers={energySuppliers} formData={formData} errors={errors} handleInputChange={handleInputChange} />;
            case 'add_gas_ean':
                return <AddGasEan step={id} currentStep={currentStep} nextStep={nextStep} energySuppliers={energySuppliers} formData={formData} errors={errors} handleInputChange={handleInputChange} />;
            case 'finish':
                return <Finish step={id} currentStep={currentStep} formData={formData} errors={errors} handleInputChange={handleInputChange} />;
        }
    };

    return (
        <Page title='onboarding'>
            <Card>
                <form onSubmit={handleSubmit(submit)} className='space-y-6'>
                    {steps.map((step, i) => (
                        <React.Fragment key={i}>{renderStep(step)}</React.Fragment>
                    ))}
                </form>
            </Card>
        </Page>
    );
};

export default Overview;
