import apiClient from '../utils/apiClient';

export default {
    login(data) {
        return apiClient.post('/login', data);
    },
    logout() {
        return apiClient.get('/logout');
    },
    forgotPassword(data) {
        return apiClient.post('/forgot-password', data);
    },
    resetPassword(data) {
        return apiClient.post('/reset-password', data);
    },
    me() {
        return apiClient.get('/me');
    },
    verifyAccount(data) {
        return apiClient.post('/verify-account', data);
    },
};
