import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import profileService from '../../services/profileService';
import { NotificationContext } from '../../context/NotificationContext';
import Input from '../../components/formElements/Input';
import RadiobuttonGroup from '../../components/formElements/RadiobuttonGroup';
import Selectbox from '../../components/formElements/Selectbox';
import Checkbox from '../../components/formElements/Checkbox';
import Card from '../../components/cards/BaseCard';
import Page from '../../components/layoutParts/Page';
import useForm from '../../hooks/useForm';
import { profileTitles } from '../../data/profileData';
import { languages } from '../../data/languageData';
import { signatureOptions } from '../../data/mandateData';
import SubmitButton from '../../components/formElements/SubmitButton';
import Button from '../../components/general/Button';
import BaseModal from '../../components/modals/BaseModal';

const initialValues = {};

const Overview = () => {
    const { t } = useTranslation();

    const notificationContext = useContext(NotificationContext);
    const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);

    const { formData, errors, handleInputChange, handleSubmit, dataChanged, reset } = useForm({
        initialValues: initialValues,
        // validationSchema: editProfileValidationSchema,
    });

    useEffect(() => {
        getProfileInfo();
    }, []);

    const getProfileInfo = async () => {
        const { data } = await profileService.getProfileInfo();
        reset(data);
    };

    const openDeactivateModal = () => setDeactivateModalOpen(true);
    const closeDeactivateModal = () => setDeactivateModalOpen(false);

    const deactivateAccount = async () => {
        await profileService.deactivateAccount();
        notificationContext.addNotification('success', 'profile_deactivated', 'profile_deactivated_description');
    };

    const submit = async (values) => {
        await profileService.updateProfile(values);
        notificationContext.addNotification('success', 'profile_updated', 'profile_updated_description');
        reset(values);
    };

    return (
        <Page title='my_profile'>
            <section>
                <form className='space-y-6' onSubmit={handleSubmit(submit)}>
                    <div className='w-full space-y-6 2xl:space-y-0 2xl:grid 2xl:grid-cols-12 2xl:gap-x-6'>
                        <div className='space-y-6 2xl:col-span-8'>
                            <Card contentClasses='space-y-4'>
                                <h2 className='text-md font-medium'>{t('general_information')}</h2>

                                <div className='grid grid-cols-12 gap-x-6 gap-y-4'>
                                    <RadiobuttonGroup direction='horizontal' containerClasses='col-span-12' label={t('title')} name='title' options={profileTitles} value={formData.title} onChange={handleInputChange} />
                                    <Input containerClasses='col-span-12 md:col-span-6' label={t('first_name')} type='text' id='first_name' name='first_name' value={formData.first_name} error={errors.first_name} onChange={handleInputChange} />
                                    <Input containerClasses='col-span-12 md:col-span-6' label={t('last_name')} type='text' id='last_name' name='last_name' value={formData.last_name} error={errors.last_name} onChange={handleInputChange} />
                                    <Input containerClasses='col-span-12 md:col-span-6' label={t('email')} type='email' id='email' name='email' value={formData.email} error={errors.email} onChange={handleInputChange} />
                                    <Input containerClasses='col-span-12 md:col-span-6' label={t('mobile')} type='text' id='mobile' name='mobile' value={formData.mobile} error={errors.mobile} onChange={handleInputChange} />
                                    <Input containerClasses='col-span-12 md:col-span-6' label={t('date_of_birth')} type='date' id='date_of_birth' name='date_of_birth' value={formData.date_of_birth} error={errors.date_of_birth} onChange={handleInputChange} />
                                    <Selectbox containerClasses='col-span-12 md:col-span-6' label={t('language')} id='language' name='language' options={languages} value={formData.language} error={errors.language} onChange={handleInputChange} />
                                </div>
                            </Card>

                            <Card contentClasses='space-y-4'>
                                <h2 className='text-md font-medium'>{t('address')}</h2>

                                <div className='grid grid-cols-12 gap-x-6 gap-y-4'>
                                    <Input containerClasses='col-span-12 md:col-span-6' label={t('street')} type='text' id='street' name='street' value={formData.street} error={errors.street} onChange={handleInputChange} />
                                    <Input containerClasses='col-span-6 md:col-span-3' label={t('number')} type='text' id='house_number' name='house_number' value={formData.house_number} error={errors.house_number} onChange={handleInputChange} />
                                    <Input containerClasses='col-span-6 md:col-span-3' label={t('addition')} type='text' id='addition' name='addition' value={formData.addition} error={errors.addition} onChange={handleInputChange} />
                                    <Input containerClasses='col-span-3 md:col-span-3' label={t('postal_code')} type='text' id='postal_code' name='postal_code' value={formData.postal_code} error={errors.postal_code} onChange={handleInputChange} />
                                    <Input containerClasses='col-span-9 md:col-span-9' label={t('city')} type='text' id='city' name='city' value={formData.city} error={errors.date_of_birth} onChange={handleInputChange} />
                                </div>
                            </Card>

                            <Card contentClasses='space-y-4'>
                                <h2 className='text-md font-medium'>{t('notifications')}</h2>

                                <div className='grid grid-cols-12 gap-x-6 gap-y-4'>
                                    <Checkbox containerClasses='col-span-12 md:col-span-3' label={t('monthly_emails')} id='monthly_emails' name='monthly_emails' value={formData.monthly_emails} error={errors.monthly_emails} onChange={handleInputChange} />
                                    <Checkbox containerClasses='col-span-12 md:col-span-3' label={t('sms_notifications')} id='sms_notifications' name='sms_notifications' value={formData.sms_notifications} error={errors.sms_notifications} onChange={handleInputChange} />
                                    <Checkbox containerClasses='col-span-12 md:col-span-3' label={t('marketing_emails')} id='marketing_emails' name='marketing_emails' value={formData.marketing_emails} error={errors.marketing_emails} onChange={handleInputChange} />
                                    <Checkbox containerClasses='col-span-12 md:col-span-3' label={t('newspaper_emails')} id='newspaper_emails' name='newspaper_emails' value={formData.newspaper_emails} error={errors.newspaper_emails} onChange={handleInputChange} />
                                </div>
                            </Card>
                        </div>

                        <div className='space-y-6 2xl:col-span-4'>
                            <Card contentClasses='space-y-4'>
                                <h2 className='text-md font-medium'>{t('payment_details')}</h2>

                                <div className='grid grid-cols-12 gap-x-6 gap-y-4'>
                                    <Input containerClasses='col-span-12 md:col-span-6 2xl:col-span-12' label={t('iban')} id='iban' name='payment.iban' value={formData.iban} error={errors.iban} onChange={handleInputChange} />
                                    <Checkbox containerClasses='col-span-12' label={t('direct_debit')} id='direct_debit' name='direct_debit' value={formData.direct_debit} error={errors.direct_debit} onChange={handleInputChange} />
                                    <Checkbox containerClasses='col-span-12' label={t('electronic_invoice')} id='electronic_invoice' name='electronic_invoice' value={formData.electronic_invoice} error={errors.electronic_invoice} onChange={handleInputChange} />
                                    {formData.electronic_invoice && <Input containerClasses='col-span-12 md:col-span-6 2xl:col-span-12' label={t('invoice_email')} id='invoice_email' name='invoice_email' value={formData.invoice_email} error={errors.invoice_email} onChange={handleInputChange} />}
                                </div>
                            </Card>

                            <Card contentClasses='space-y-4'>
                                <h2 className='text-md font-medium'>{t('mandate')}</h2>

                                <div className='grid grid-cols-12 gap-x-6 gap-y-4'>
                                    <RadiobuttonGroup direction='vertical' containerClasses='col-span-12' label={t('signature_option')} name='signature_option' options={signatureOptions} value={formData.signature_option} onChange={handleInputChange} />
                                </div>
                            </Card>

                            {/* <Card contentClasses='space-y-4'>
                                <h2 className='text-md font-medium'>{t('account')}</h2>

                                <Button buttonClasses='md:w-auto' color='red' label='deactive_account' onClick={openDeactivateModal} />
                            </Card> */}
                        </div>
                    </div>

                    <SubmitButton disabled={!dataChanged} buttonClasses='md:w-auto' label='update_profile' />
                </form>
            </section>

            <BaseModal isOpen={deactivateModalOpen} closeModal={closeDeactivateModal}>
                <form onSubmit={handleSubmit(submit)}>
                    <div className='p-6 space-y-6'>
                        <h3 className='text-2xl font-medium leading-6 text-gray-900'>{t('deactivate_account_title')}</h3>

                        <div>
                            <p className='text-sm text-gray-900 font-semibold'>{t('deactivate_account_subtitle')}</p>
                            <p className='text-sm text-gray-500'>{t('deactivate_account_description')}</p>
                        </div>
                    </div>
                    <div className='p-6 bg-gray-50 sm:flex sm:flex-row-reverse sm:gap-4'>
                        <Button type='button' color='red' label='deactivate_account' onClick={deactivateAccount} />
                        <Button containerClasses='mt-3 sm:mt-0' type='button' color='transparant' label='cancel' onClick={closeDeactivateModal} />
                    </div>
                </form>
            </BaseModal>
        </Page>
    );
};

export default Overview;
