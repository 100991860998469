import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import useForm from '../../hooks/useForm';
import { AuthContext } from '../../context/AuthContext';
import { NotificationContext } from '../../context/NotificationContext';

import { verifyAccountValidationSchema } from '../../validators/account';

import Input from '../../components/formElements/Input';
import SubmitButton from '../../components/formElements/SubmitButton';
import authService from '../../services/authService';

const initialValues = {
    // password: '12345678',
    // password_confirmation: '12345678',
    password: '',
    password_confirmation: '',
};

const VerifyAccount = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const authContext = useContext(AuthContext);
    const notificationContext = useContext(NotificationContext);

    const { formData, errors, handleInputChange, handleSubmit } = useForm({
        initialValues: initialValues,
        validationSchema: verifyAccountValidationSchema,
    });

    const submit = async (values) => {
        const { data } = await authService.verifyAccount({
            password: values.password,
            account_verification_token: searchParams.get('token'),
        });

        console.log('Data is: ', data);

        notificationContext.addNotification('success', 'account_verified', 'account_verified_description');
        authContext.setAuthInfo(data);
        navigate('/mandate/sign');
    };

    return (
        <div className='w-full border border-grey-300 rounded-lg overflow-hidden shadow sm:mx-auto sm:w-full sm:max-w-md'>
            <div className='p-10 bg-white'>
                <div className='space-y-4'>
                    <h2 className='text-sm font-medium tracking-wider uppercase text-gaele-light-blue'>{t('verify_account')}</h2>
                    <h3 className='text-3xl font-medium tracking-wider text-gray-800'>{t('set_password')}</h3>
                </div>
                <form onSubmit={handleSubmit(submit)} className='mt-10 space-y-6'>
                    <Input label='password' type='password' id='password' name='password' value={formData.password} error={errors.password} onChange={handleInputChange} />
                    <Input label='confirm_password' type='password' id='password_confirmation' name='password_confirmation' value={formData.password_confirmation} error={errors.password_confirmation} onChange={handleInputChange} />

                    <SubmitButton label='set_password' />
                </form>
            </div>
        </div>
    );
};

export default VerifyAccount;
