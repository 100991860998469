import * as yup from 'yup';

export const createSupportTicketValidationSchema = yup.object().shape({
    title: yup.string().required('required_field').min(5, 'title_too_short'),
    message: yup.string().required('required_field').min(30, 'message_too_short'),
});

export const addMessageToTicketValidationSchema = yup.object().shape({
    message: yup.string().required('required_field').min(30, 'message_too_short'),
});
