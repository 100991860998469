import apiClient from '../utils/apiClient';

export default {
    getEnergyOffers() {
        return apiClient.get(`/energy-offers`);
    },
    getEnergyOffer(id) {
        return apiClient.get(`/energy-offers/${id}`);
    },
    declineEnergyOffer(id) {
        return apiClient.get(`/energy-offers/${id}/decline`);
    },
    acceptEnergyOffer(id) {
        return apiClient.get(`/energy-offers/${id}/accept`);
    },
};
