import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const allowedCharactersValues = ['alpha', 'numeric', 'alphanumeric'];

const propsMap = {
    alpha: {
        type: 'text',
        inputMode: 'text',
        pattern: '[a-zA-Z]{1}',
    },

    alphanumeric: {
        type: 'text',
        inputMode: 'text',
        pattern: '[a-zA-Z0-9]{1}',
    },

    numeric: {
        type: 'number',
        inputMode: 'numeric',
        pattern: '[0-9]{1}',
        min: '0',
        max: '9',
    },
};

const CodeInput = ({ containerClasses, label, name, id, allowedCharacters = 'numeric', ariaLabel, autoFocus = true, disabled, length = 6, isPassword = false, error, onChange }, ref) => {
    const { t } = useTranslation();

    if (isNaN(length) || length < 1) {
        throw new Error('Length should be a number and greater than 0');
    }

    if (!allowedCharactersValues.some((value) => value === allowedCharacters)) {
        throw new Error('Invalid value for allowedCharacters. Use alpha, numeric, or alphanumeric');
    }

    const inputsRef = useRef([]);
    const inputProps = propsMap[allowedCharacters];

    useEffect(() => {
        if (autoFocus) {
            inputsRef.current[0].focus();
        }
    }, []);

    const sendResult = () => {
        const res = inputsRef.current.map((input) => input.value).join('');
        onChange &&
            onChange({
                target: {
                    type: 'text',
                    name: name,
                    value: res,
                },
            });
    };

    const handleOnChange = (e) => {
        const {
            target: { value, nextElementSibling },
        } = e;
        if (value.length > 1) {
            e.target.value = value.charAt(0);
            if (nextElementSibling !== null) {
                nextElementSibling.focus();
            }
        } else {
            if (value.match(inputProps.pattern)) {
                if (nextElementSibling !== null) {
                    nextElementSibling.focus();
                }
            } else {
                e.target.value = '';
            }
        }
        sendResult();
    };

    const handleOnKeyDown = (e) => {
        const { key } = e;
        const target = e.target;
        if (key === 'Backspace') {
            if (target.value === '') {
                if (target.previousElementSibling !== null) {
                    const t = target.previousElementSibling;
                    t.value = '';
                    t.focus();
                    e.preventDefault();
                }
            } else {
                target.value = '';
            }
            sendResult();
        }
    };

    const handleOnFocus = (e) => {
        e.target.select();
    };

    const handleOnPaste = (e) => {
        const pastedValue = e.clipboardData.getData('Text');

        let currentInput = 0;

        for (let i = 0; i < pastedValue.length; i++) {
            const pastedCharacter = pastedValue.charAt(i);
            const currentValue = inputsRef.current[currentInput].value;
            if (pastedCharacter.match(inputProps.pattern)) {
                if (!currentValue) {
                    inputsRef.current[currentInput].value = pastedCharacter;
                    if (inputsRef.current[currentInput].nextElementSibling !== null) {
                        inputsRef.current[currentInput].nextElementSibling.focus();
                        currentInput++;
                    }
                }
            }
        }
        sendResult();

        e.preventDefault();
    };

    const inputs = [];

    for (let i = 0; i < length; i++) {
        inputs.push(
            <input
                key={i}
                onChange={handleOnChange}
                onKeyDown={handleOnKeyDown}
                onFocus={handleOnFocus}
                onPaste={handleOnPaste}
                {...inputProps}
                type={isPassword ? 'password' : inputProps.type}
                ref={(el) => {
                    inputsRef.current[i] = el;
                }}
                maxLength={1}
                className='text-center border-gray-300 py-3 rounded-md focus:ring-indigo-500 focus:border-indigo-500'
                autoComplete={i === 0 ? 'one-time-code' : 'off'}
                aria-label={ariaLabel ? `${ariaLabel}. Character ${i + 1}.` : `Character ${i + 1}.`}
                disabled={disabled}
            />,
        );
    }

    return (
        <div className={containerClasses}>
            {label && (
                <label htmlFor={id} className='block text-sm font-light tracking-wider text-gray-700'>
                    {t(label)}
                </label>
            )}

            <div className='relative w-full mt-2'>
                <div className='flex justify-between space-x-4'>{inputs}</div>
            </div>
            {error && <span className='mt-2 text-sm text-red-600'>{t(error)}</span>}
        </div>
    );
};

export default CodeInput;
