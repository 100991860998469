import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../services/authService';

const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        getUserInfo();
    }, []);

    const getUserInfo = async () => {
        try {
            const { data } = await authService.me();

            setIsAuthenticated(true);
            setUser(data);
        } catch (error) {
            setIsAuthenticated(false);
            setUser({});
        }
    };

    const setAuthInfo = (user) => {
        setIsAuthenticated(!!(user && user.id));
        setUser(user);
    };

    const logout = async () => {
        await authService.logout();

        setIsAuthenticated(false);
        setUser({});

        navigate('/login');
    };

    return <AuthContext.Provider value={{ isAuthenticated, user, setAuthInfo, logout }}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
