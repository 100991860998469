import React, { createContext, useState } from 'react';

const NotificationContext = createContext();

const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);

    const addNotification = (type, title, description) => {
        const notification = {
            id: Date.now(),
            type,
            title,
            description,
        };

        setNotifications((currentNotifications) => [...currentNotifications, notification]);
    };

    const removeNotification = (id) => {
        setNotifications((currentNotifications) => currentNotifications.filter((notification) => notification.id !== id));
    };

    return <NotificationContext.Provider value={{ notifications, addNotification, removeNotification }}>{children}</NotificationContext.Provider>;
};

export { NotificationContext, NotificationProvider };
