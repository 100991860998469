import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { NotificationContext } from '../../context/NotificationContext';
import Page from '../../components/layoutParts/Page';
import useForm from '../../hooks/useForm';
import eanService from '../../services/eanService';
import invoiceService from '../../services/invoiceService';
import { eanTypes, eanMeterTypes, eanReasons, eanMeterCheckPeriods } from '../../data/eanData';
import Card from '../../components/cards/BaseCard';
import RadiobuttonGroup from '../../components/formElements/RadiobuttonGroup';
import Input from '../../components/formElements/Input';
import Checkbox from '../../components/formElements/Checkbox';
import Selectbox from '../../components/formElements/Selectbox';

import { addEanValidationSchema } from '../../validators/ean';
import SubmitButton from '../../components/formElements/SubmitButton';
import Button from '../../components/general/Button';

const AddEan = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const notificationContext = useContext(NotificationContext);
    const [searchParams] = useSearchParams();

    const initialValues = {
        ean_type: searchParams.get('type') ? searchParams.get('type') : 'E',
        ean_code: '',
        has_digital_meter: false,
        has_solar_installation: false,
    };

    const [energySuppliers, setEnergySuppliers] = useState([]);

    const { formData, errors, handleInputChange, handleSubmit, setValue } = useForm({
        initialValues: initialValues,
        validationSchema: addEanValidationSchema,
    });

    useEffect(() => {
        getEnergySuppliers();
    }, []);

    const getEnergySuppliers = async () => {
        const { data } = await eanService.getEnergySuppliers();
        setEnergySuppliers(data.energy_suppliers);
    };

    const getMeterTariffs = (meter_type) => {
        switch (meter_type) {
            case 'YMR':
            case 'MMR':
                return [
                    { label: 'mono', value: 'mono' },
                    { label: 'dual', value: 'dual' },
                    { label: 'mono_exclusive', value: 'mono_exclusive' },
                    { label: 'dual_exclusive', value: 'dual_exclusive' },
                ];
            case 'AMR':
            case 'SMR':
                return [{ label: 'dual', value: 'dual' }];
            default:
                return [];
        }
    };

    const getEnergySupplierOptions = (suppliers, type) => {
        let supplier_options = [];

        suppliers.forEach((supplier) => {
            if (supplier.type.includes(type)) {
                supplier_options.push({
                    label: supplier.display_name,
                    value: supplier.code,
                });
            }
        });

        return supplier_options;
    };

    const handleFileChange = async (e) => {
        e.persist();

        const file = e.target.files[0];
        const file_name = file.name;

        const { data } = await invoiceService.getUploadUrl(file_name);
        const upload_url = data.url;

        console.log(upload_url);

        const response = await invoiceService.uploadInvoice(upload_url, file);
        console.log(response);
        console.log(response.data);
    };

    const submit = async (values) => {
        await eanService.createEan(values);
        notificationContext.addNotification('success', 'new_ean', 'new_ean_description');
        navigate('/eans');
    };

    // useEffect(() => {
    //     if (formData.ean_type === 'E') {
    //         setValue('meter_tariff', '');
    //     }

    //     if (formData.ean_type === 'G') {
    //         setValue('meter_tariff', 'mono');
    //     }

    //     if (formData.meter_type !== 'YMR') {
    //         setValue('meter_check_period', '');
    //     }

    //     setValue('meter_tariff', '');
    // }, [formData.meter_type, formData.ean_type]);

    // useEffect(() => {
    //     if (formData.ean_type === 'G') {
    //         setValue('meter_tariff', 'mono');
    //     }

    //     if (formData.ean_type === 'E') {
    //         setValue('meter_tariff', '');
    //     }
    // }, [formData.ean_type]);

    // console.log('FormData: ', formData);

    return (
        <Page title='add_ean'>
            <form onSubmit={handleSubmit(submit)} className='space-y-6'>
                <Card contentClasses='space-y-2'>
                    <h2 className='text-md font-medium'>{t('general_information')}</h2>

                    <div className='grid grid-cols-12 gap-x-6 gap-y-4'>
                        <RadiobuttonGroup containerClasses='col-span-12' direction='horizontal' label='ean_type' name='ean_type' options={eanTypes} value={formData.ean_type} onChange={handleInputChange} />

                        <Input containerClasses='col-span-12 md:col-span-4' label='ean_code' type='number' id='ean_code' name='ean_code' placeholder='5414' value={formData.ean_code} error={errors.ean_code} onChange={handleInputChange} />
                        {/* <Selectbox containerClasses='col-span-12 md:col-span-4' label='registration_reason' id='reason' name='reason' options={eanReasons} value={formData.reason} error={errors.reason} onChange={handleInputChange} /> */}
                        <Selectbox containerClasses='col-span-12 md:col-span-4' label='meter_type' id='meter_type' name='meter_type' options={eanMeterTypes} value={formData.meter_type} error={errors.meter_type} onChange={handleInputChange} />

                        {formData.meter_type === 'YMR' && <Selectbox containerClasses='col-span-12 md:col-span-4' label='meter_check_period' id='meter_check_period' name='meter_check_period' options={eanMeterCheckPeriods} value={formData.meter_check_period} error={errors.meter_check_period} onChange={handleInputChange} />}
                        {formData.ean_type === 'E' && formData.meter_type && <Selectbox containerClasses='col-span-12 md:col-span-4' label='meter_tariff' id='meter_tariff' name='meter_tariff' options={getMeterTariffs(formData.meter_type)} value={formData.meter_tariff} error={errors.meter_tariff} onChange={handleInputChange} />}

                        {formData.meter_tariff === 'mono' && (
                            <div className='col-span-12 grid grid-cols-12 gap-x-6 gap-y-4'>
                                <Input containerClasses='col-span-12 md:col-span-4' label='mono_volume' trailing='kwh_year' type='number' id='mono_volume' name='mono_volume' placeholder='3500' value={formData.mono_volume} error={errors.mono_volume} onChange={handleInputChange} />
                            </div>
                        )}

                        {formData.meter_tariff === 'dual' && (
                            <div className='col-span-12 grid grid-cols-12 gap-x-6 gap-y-4'>
                                <Input containerClasses='col-span-12 md:col-span-4' label='day_volume' trailing='kwh_year' type='number' id='day_volume' name='day_volume' value={formData.day_volume} error={errors.day_volume} onChange={handleInputChange} />
                                <Input containerClasses='col-span-12 md:col-span-4' label='night_volume' trailing='kwh_year' type='number' id='night_volume' name='night_volume' value={formData.night_volume} error={errors.night_volume} onChange={handleInputChange} />
                            </div>
                        )}

                        {formData.meter_tariff === 'mono_exclusive' && (
                            <div className='col-span-12 grid grid-cols-12 gap-x-6 gap-y-4'>
                                <Input containerClasses='col-span-12 md:col-span-4' label='mono_volume' trailing='kwh_year' type='number' id='mono_volume' name='mono_volume' value={formData.mono_volume} error={errors.mono_volume} onChange={handleInputChange} />
                                <Input containerClasses='col-span-12 md:col-span-4' label='exclusive_volume' trailing='kwh_year' type='number' id='exclusive_volume' name='exclusive_volume' value={formData.exclusive_volume} error={errors.exclusive_volume} onChange={handleInputChange} />
                            </div>
                        )}

                        {formData.meter_tariff === 'dual_exclusive' && (
                            <div className='col-span-12 grid grid-cols-12 gap-x-6 gap-y-4'>
                                <Input containerClasses='col-span-12 md:col-span-4' label='day_volume' trailing='kwh_year' type='number' id='day_volume' name='day_volume' value={formData.day_volume} error={errors.day_volume} onChange={handleInputChange} />
                                <Input containerClasses='col-span-12 md:col-span-4' label='night_volume' trailing='kwh_year' type='number' id='night_volume' name='night_volume' value={formData.night_volume} error={errors.night_volume} onChange={handleInputChange} />
                                <Input containerClasses='col-span-12 md:col-span-4' label='exclusive_volume' trailing='kwh_year' type='number' id='exclusive_volume' name='exclusive_volume' value={formData.exclusive_volume} error={errors.exclusive_volume} onChange={handleInputChange} />
                            </div>
                        )}
                    </div>
                </Card>

                <Card contentClasses='space-y-2'>
                    <h2 className='text-md font-medium'>{t('current_contract')}</h2>

                    <div className='grid grid-cols-12 gap-x-6 gap-y-4'>
                        <Selectbox containerClasses='col-span-12 md:col-span-4' label='supplier' id='supplier' name='supplier' options={getEnergySupplierOptions(energySuppliers, formData.ean_type)} value={formData.supplier} error={errors.supplier} onChange={handleInputChange} />
                        <Input containerClasses='col-span-12 md:col-span-4' label='end_date_contract' type='date' id='end_date_contract' name='end_date_contract' value={formData.end_date_contract} error={errors.end_date_contract} onChange={handleInputChange} />

                        {formData.meter_tariff === 'mono' && (
                            <>
                                <Input containerClasses='col-span-12 md:col-span-4' label='mono_price' trailing='eur_kwh' type='number' id='mono_price' name='mono_price' placeholder='0,3' value={formData.mono_price} error={errors.mono_price} onChange={handleInputChange} />
                            </>
                        )}

                        {formData.meter_tariff === 'dual' && (
                            <div className='col-span-12 grid grid-cols-12 gap-x-6 gap-y-4'>
                                <Input containerClasses='col-span-12 md:col-span-4' label='day_price' trailing='eur_kwh' type='number' id='day_price' name='day_price' value={formData.day_price} error={errors.day_price} onChange={handleInputChange} />
                                <Input containerClasses='col-span-12 md:col-span-4' label='night_price' trailing='eur_kwh' type='number' id='night_price' name='night_price' value={formData.night_price} error={errors.night_price} onChange={handleInputChange} />
                            </div>
                        )}

                        {formData.meter_tariff === 'mono_exclusive' && (
                            <div className='col-span-12 grid grid-cols-12 gap-x-6 gap-y-4'>
                                <Input containerClasses='col-span-12 md:col-span-4' label='mono_price' trailing='eur_kwh' type='number' id='mono_price' name='mono_price' value={formData.mono_price} error={errors.mono_price} onChange={handleInputChange} />
                                <Input containerClasses='col-span-12 md:col-span-4' label='exclusive_price' trailing='eur_kwh' type='number' id='exclusive_price' name='exclusive_price' value={formData.exclusive_price} error={errors.exclusive_price} onChange={handleInputChange} />
                            </div>
                        )}

                        {formData.meter_tariff === 'dual_exclusive' && (
                            <div className='col-span-12 grid grid-cols-12 gap-x-6 gap-y-4'>
                                <Input containerClasses='col-span-12 md:col-span-4' label='day_price' trailing='eur_kwh' type='number' id='day_price' name='day_price' value={formData.day_price} error={errors.day_price} onChange={handleInputChange} />
                                <Input containerClasses='col-span-12 md:col-span-4' label='night_price' trailing='eur_kwh' type='number' id='night_price' name='night_price' value={formData.night_price} error={errors.night_price} onChange={handleInputChange} />
                                <Input containerClasses='col-span-12 md:col-span-4' label='exclusive_price' trailing='eur_kwh' type='number' id='exclusive_price' name='exclusive_price' value={formData.exclusive_price} error={errors.exclusive_price} onChange={handleInputChange} />
                            </div>
                        )}
                    </div>

                    <div className='grid grid-cols-12 gap-x-6 gap-y-4'>
                        <Input containerClasses='col-span-12 md:col-span-4' label='fixed_fee' trailing='eur_year' type='text' id='fixed_fee' name='fixed_fee' value={formData.fixed_fee} error={errors.fixed_fee} onChange={handleInputChange} />
                        {formData.ean_type === 'E' && <Input containerClasses='col-span-12 md:col-span-4' label='wwk' trailing='eur_kwh' type='text' id='wkk' name='wkk' value={formData.wkk} error={errors.wkk} onChange={handleInputChange} />}
                        {formData.ean_type === 'E' && <Input containerClasses='col-span-12 md:col-span-4' label='gsc' trailing='eur_kwh' type='text' id='gsc' name='gsc' value={formData.gsc} error={errors.gsc} onChange={handleInputChange} />}
                    </div>
                </Card>

                {formData.ean_type === 'E' && (
                    <Card contentClasses='space-y-2'>
                        <h2 className='text-md font-medium'>{t('solar_installation')}</h2>

                        <div className='grid grid-cols-12 gap-x-6 gap-y-4'>
                            <Checkbox containerClasses='col-span-12' label='has_solar_installation' id='has_solar_installation' name='has_solar_installation' value={formData.has_solar_installation} error={errors.has_solar_installation} onChange={handleInputChange} />

                            {formData.has_solar_installation && (
                                <>
                                    <Input containerClasses='col-span-12 md:col-span-4' trailing='kva' label='power_inverter' type='number' id='power_inverter' name='power_inverter' placeholder={4600} value={formData.power_inverter} error={errors.power_inverter} onChange={handleInputChange} />
                                </>
                            )}
                        </div>
                    </Card>
                )}

                <Card contentClasses='space-y-2'>
                    <h2 className='text-md font-medium'>{t('address')}</h2>

                    <div className='grid grid-cols-12 gap-x-6 gap-y-4'>
                        <Checkbox containerClasses='col-span-12' label='same_as_profile_address' id='is_profile_address' name='is_profile_address' value={formData.is_profile_address} error={errors.is_profile_address} onChange={handleInputChange} />

                        {!formData.is_profile_address && (
                            <>
                                <Input containerClasses='col-span-12 md:col-span-6' label={t('street')} type='text' id='street' name='street' value={formData.street} error={errors.street} onChange={handleInputChange} />
                                <Input containerClasses='col-span-6 md:col-span-3' label={t('number')} type='text' id='house_number' name='house_number' value={formData.house_number} error={errors.house_number} onChange={handleInputChange} />
                                <Input containerClasses='col-span-6 md:col-span-3' label={t('addition')} type='text' id='addition' name='addition' value={formData.addition} error={errors.addition} onChange={handleInputChange} />
                                <Input containerClasses='col-span-3 md:col-span-3' label={t('postal_code')} type='text' id='postal_code' name='postal_code' value={formData.postal_code} error={errors.postal_code} onChange={handleInputChange} />
                                <Input containerClasses='col-span-9 md:col-span-9' label={t('city')} type='text' id='city' name='city' value={formData.city} error={errors.date_of_birth} onChange={handleInputChange} />
                            </>
                        )}
                    </div>
                </Card>

                <div className='flex gap-4'>
                    <Button to='/eans' containerClasses='mt-3 sm:mt-0' color='transparant' label='cancel' />
                    <SubmitButton label='create_ean' />
                </div>
            </form>
        </Page>
    );
};

export default AddEan;
