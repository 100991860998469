import Icon from '@mdi/react';
import { mdiCheckCircle } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import React from 'react';

const Title = ({ step, currentStep, label }) => {
    const { t } = useTranslation();

    if (step < currentStep) {
        return (
            <div className='flex'>
                <div className='-ml-2.5 flex-shrink-0 h-5 w-5 relative flex items-center justify-center' aria-hidden='true'>
                    <span className='h-3/4 w-3/4 text-indigo-600'>
                        <Icon path={mdiCheckCircle} />
                    </span>
                </div>
                <p className='ml-2 text-sm font-medium text-gray-500 hover:text-gray-700 cursor-pointer select-none'>{label}</p>
            </div>
        );
    } else if (step === currentStep) {
        return (
            <div className='flex'>
                <div className='-ml-2.5 flex-shrink-0 h-5 w-5 relative flex items-center justify-center' aria-hidden='true'>
                    <span className='absolute h-4 w-4 rounded-full bg-indigo-200' />
                    <span className='relative block w-2 h-2 bg-indigo-600 rounded-full' />
                </div>
                <p className='ml-2 text-sm font-medium text-indigo-600 select-none'>{label}</p>
            </div>
        );
    } else {
        return (
            <div className='flex'>
                <div className='-ml-2.5 flex-shrink-0 h-5 w-5 relative flex items-center justify-center' aria-hidden='true'>
                    <span className='h-2 w-2 bg-gray-300 rounded-full' />
                </div>
                <p className='ml-2 text-sm font-medium text-gray-300 select-none'>{label}</p>
            </div>
        );
    }
};

export default Title;
