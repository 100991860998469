import React from 'react';
import { useTranslation } from 'react-i18next';
import { CloudUploadIcon } from '@heroicons/react/outline';

const FileUploadButton = ({ containerClasses, id, name, disabled, multiple = false, error, onChange }) => {
    const { t } = useTranslation();

    return (
        <div>
            <div className='relative border border-gaele-gas border-dashed rounded-md p-4 cursor-pointer'>
                <div className='flex items-center text-gray-400 space-x-2'>
                    <CloudUploadIcon className='h-5 w-5' aria-hidden='true' />
                    <span>
                        {t('drag_and_drop_description')} <span className='underline text-blue-400'>{t('choose_files')}</span>
                    </span>
                    <input type='file' title='' name={name} multiple={multiple} onChange={onChange} className='absolute inset-0 opacity-0 w-full min-h-full cursor-pointer' />
                </div>
            </div>
        </div>
    );
};

export default FileUploadButton;

// {
//     /* <div :className="{ 'border-indigo-400 bg-blue-50': dragging, 'border-gray-200 bg-white': !dragging }" className="group cursor-pointer relative w-full border-dashed border-2 hover:border-indigo-400 hover:bg-blue-50 transition ease-in-out duration-300 bg-white px-8 py-6 rounded-md"> */
// }
// <div className='flex items-center'>
//     {/* <PaperClipIcon :className="{ 'text-indigo-400': dragging, 'text-gray-200': !dragging }" className="h-12 w-12 group-hover:text-indigo-400 transition ease-in-out duration-300 mr-8" aria-hidden="true" /> */}
//     <div>
//         <p className='block mb-1 text-gray-800 font-medium'>
//             <span>
//                 {t('drag')} {t('or')}
//             </span>
//             <span className='text-indigo-400'>&nbsp;{t('select_a_file')}</span>
//         </p>
//         {/* <p className='block text-gray-400 text-sm font-normal'>Max. file size: {{ maxFileSize }}MB</p> */}
//         {/* <p className='block text-gray-400 text-sm font-normal'>Allowed extensions: {{ displayAcceptedFileTypes }}</p> */}
//     </div>
//     <input className='absolute inset-0 opacity-0 w-full min-h-full cursor-pointer' type='file' id={id} name={name} multiple={multiple} onChange={onChange} disabled={disabled} title='' autoComplete='off' />
//     {/* </div> */}
// </div>;

// {
//     /* <div className='border border-dashed border-gaele-gas rounded-md p-4'>

//     <div className={`relative w-full mt-1 border-b ${disabled ? 'border-transparent' : 'border-gray-300'} focus-within:border-indigo-600`}>
//         <CloudUploadIcon className='h-5 w-5 text-blue-400' aria-hidden='true' />
//         <input type='file' id={id} name={name} multiple={multiple} onChange={onChange} disabled={disabled} autoComplete='off' />
//     </div>

// </div> */
// }
// {
//     error && <span className='mt-2 text-sm text-red-600'>{t(error)}</span>;
// }
