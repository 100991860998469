import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';

const NotificationItem = ({ type = 'error', id, title, description, removeNotification }) => {
    const { t } = useTranslation();
    let timer = null;

    useEffect(() => {
        startTimer();
    }, []);

    const startTimer = () => {
        timer = setTimeout(remove, 6000);
    };

    const clearTimer = () => {
        clearTimeout(timer);
    };

    const remove = () => {
        removeNotification(id);
    };

    return (
        <div className='max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden' onMouseEnter={clearTimer} onMouseLeave={startTimer}>
            <div className='p-4'>
                <div className='flex'>
                    <div className='flex-shrink-0'>{type === 'success' ? <CheckCircleIcon className='h-6 w-6 text-green-400' aria-hidden='true' /> : <ExclamationCircleIcon className='h-6 w-6 text-red-400' aria-hidden='true' />}</div>
                    <div className='mx-4 flex-1'>
                        <p className='text-sm font-medium text-gray-900'>{t(title)}</p>
                        <p v-show='description' className='mt-1 text-sm text-gray-500'>
                            {t(description)}
                        </p>
                    </div>
                    <div className='flex-shrink-0'>
                        <button onClick={remove} className='bg-white cursor-pointer rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                            <span className='sr-only'>Close</span>
                            <XIcon className='h-5 w-5' aria-hidden='true' />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotificationItem;
