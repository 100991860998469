import React from 'react';
import { useTranslation } from 'react-i18next';

const Selectbox = ({ label, id, name, options, value, error, onChange, containerClasses }) => {
    const { t } = useTranslation();

    console.log('Value van de selectbox: ', value);

    return (
        <div className={containerClasses}>
            {label && (
                <label htmlFor={id} className='block text-sm font-light tracking-wider text-gray-700'>
                    {t(label)}
                </label>
            )}
            <div className='mt-1 border-b border-gray-300 focus-within:border-indigo-600'>
                <select className='block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm' id={id} name={name} value={value} onChange={onChange}>
                    <option value='' disabled selected readOnly='true'>
                        Choose an option
                    </option>
                    {options.map((option, i) => (
                        <option key={i} value={option.value}>
                            {t(option.label)}
                        </option>
                    ))}
                </select>
            </div>
            {error && <span className='mt-2 text-sm text-red-600'>{t(error)}</span>}
        </div>
    );
};

export default Selectbox;
