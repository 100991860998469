import React from 'react';

const BaseCard = ({ containerClasses, contentClasses, children }) => {
    return (
        <div className={`relative w-full bg-white border border-grey-100 rounded-lg shadow ${containerClasses}`}>
            <div className={`p-6 ${contentClasses}`}>{children}</div>
        </div>
    );
};

export default BaseCard;
