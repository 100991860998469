import axios from 'axios';

const externalClient = axios.create({
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    timeout: 10000,
});

export default externalClient;
