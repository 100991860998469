import React from 'react';
import { useTranslation } from 'react-i18next';

const SubmitButton = ({ label, children, disabled, containerClasses, buttonClasses }) => {
    const { t } = useTranslation();

    const baseClasses = 'w-full flex justify-center py-3 px-10 border border-transparent rounded-md shadow-sm text-sm font-medium bg-gaele-light-blue hover:bg-gaele-dark-blue text-white disabled:bg-slate-50 disabled:text-slate-400 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500';

    return (
        <div className={containerClasses}>
            <button type='submit' disabled={disabled} className={`${baseClasses} ${buttonClasses}`}>
                {children ? { children } : t(label)}
            </button>
        </div>
    );
};

export default SubmitButton;
