export default {
    ean: 'EAN',
    eans: 'EANs',
    add_ean: 'Add EAN',
    ean_details: 'EAN details',

    platform_name: 'Gaele',
    platform_tagline: 'Your personal energy assistant',
    platform_street: 'Kortrijksesteenweg',
    platform_house_number: '387',
    platform_postal_code: '8530',
    platform_city: 'Harelbeke',
    platform_phone: '+32 2 899 04 68',

    sign_in: 'Sign in',
    sign_up: 'Sign up',
    welcome_back: 'Welcome back',
    no_account: "Don't have an account",
    deactivated_account: 'Deactivated account',
    restore: 'Restore',
    forgot_your_password: 'Forgot your password',
    already_an_account: 'Already an account',

    // Login
    login: 'Login',
    email: 'Email',
    email_address: 'Email address',
    password: 'Password',
    confirm_password: 'Confirm password',

    // Forgot password
    forgot_password: 'Forgot password',
    check_inbox: 'Check your inbox',
    password_reset_instructions: 'We have sent you instructions to reset your password',

    // Reset password
    reset_password: 'Reset password',
    choose_new_password: 'Choose a new password',
    set_new_password: 'Set new password',
    remember_password: 'Remember your password',
    new_password: 'New password',
    reset_password_succeed: 'You succesfully set a new password',

    // Verify account
    verify_account: 'Verify account',
    set_password: 'Set a password',
    account_verified: 'Account verified',
    account_verified_description: 'Your account has successfully been verified',

    // Sign mandate
    confirm_mandate: 'Confirm mandate',
    confirm_mandate_description: 'We sent a 6-digit code to <1>{{ phone }}</1>. Please enter this code here to confirm your mandate.',
    confirmation_code: 'Confirmation code',
    sign_mandate: 'Sign mandate',

    // Verify mandate
    mandate_verified: 'Mandate verified',
    mandate_verified_description: 'Your energy mandate has successfully been verified',

    // Onboarding
    onboarding: 'Onboarding',
    intro_title: 'Hello there!',
    intro_description_one: 'We found that you have no EANs setted up right now.',
    intro_description_two: "Without this information we can't find the best offer for you.",
    intro_description_three: 'So what the next step?',
    intro_description_four: "Just fill in the data, and then it's up to us!",
    next_step: 'Next step',
    previous_step: 'Previous step',
    intro: 'Intro',
    select_number_of_eans: 'Select number of EANs',
    skip: 'Skip',
    complete: 'Complete',

    step_two_title: 'Please select the right number of EANs you have.',
    step_two_description_one: 'This will help us to separate input-forms for you while entering boring data into right fields.',
    step_two_description_two: 'So what the next step?',
    step_two_description_three: "Just fill in the data, and then it's up to us!",

    number_of_electricity_eans: 'Number of electricity EANs',
    number_of_gas_eans: 'Number of gas EANs',

    eans_colors_description: 'Everything that relates to electricity will have a <1>yellow color</1>, and that which relates to gas will be <3>blue colored</3>. This will help to structure a large amount of information on your screen.',

    // Sidebar
    my_profile: 'My profile',
    my_eans: 'My EANs',
    ask_question: 'Ask question',
    contracts: 'Contracts',

    // EANs
    verified_eans: 'Verified EANs',
    add_electricity_ean: 'Add electricity EAN',
    add_gas_ean: 'Add gas EAN',
    ean_location: 'EAN location',
    select_invoice: 'Select invoice',
    upload_invoice_tooltip_title: 'Did you know that we can make a lot of work for you?',
    upload_invoice_tooltip_subtitle: 'Just upload your latest invoice or receipt, and we will process it and do necessary changes.',
    upload_invoice_tooltip_link: 'See all uploaded invoices',
    no_invoices: 'No invoices',
    invoice_deleted: 'Invoice deleted',
    invoice_deleted_description: 'Your invoice has been successfully deleted',
    ean_updated: 'EAN updated',
    ean_updated_description: 'You succesfully updated the EAN',
    send_invoices: 'Send invoices',
    drag_and_drop_description: 'Drag and drop files here or',
    choose_files: 'choose files',
    ean_deleted: 'EAN deleted',
    ean_deleted_description: 'Your EAN has been successfully deleted',

    // Add EAN
    electricity: 'Electricity',
    gas: 'Gas',
    ean_type: 'EAN type',
    ean_code: 'EAN code',
    meter_type: 'Meter type',
    meter_tariff: 'Meter tariff',
    has_digital_meter: 'I have a digital meter',
    ymr: 'Yearly meter reading (YMR)',
    mmr: 'Monthly meter reading (MMR)',
    amr: 'Automatic meter reading (AMR)',
    smr: 'Digital meter reading (SMR)',
    mono: 'Mono',
    mono_volume: 'Mono volume',
    mono_price: 'Mono price',
    mono_exclusive: 'Mono exclusive',
    dual: 'Dual',
    dual_exclusive: 'Dual exclusive',
    day_volume: 'Day volume',
    day_price: 'Day price',
    night_volume: 'Night volume',
    night_price: 'Night price',
    exclusive_volume: 'Exclusive volume',
    exclusive_price: 'Exclusive price',
    wwk: 'Combined heat and power (CHP)',
    gsc: 'Green certificate (GSC)',
    fixed_fee: 'Fixed fee',
    kva: 'kVA',
    kwh: 'kWh',
    kwh_year: 'kWh/Year',
    eur_kwh: '€/kWh',
    eur_year: '€/Year',
    solar_installation: 'Solar installation',
    has_solar_installation: 'I have a solar installation',
    power_inverter: 'Power inverter',
    meter_check_period: 'Meter check period',
    automatic: 'Automatic',
    monthly: 'Monthly',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'Augustus',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    current_contract: 'Current contract',
    supplier: 'Supplier',
    registration_reason: 'Registration reason',
    supplier_drop: 'I will be dropped from my supplier',
    moving: 'I moved',
    new_meter: 'My meter is a brand new meter',
    switch: "It's a standard switch",
    reopening: 'My meter needs to be reopened',
    end_date_contract: 'End date contract',
    same_as_profile_address: 'Same address as profile',
    create_ean: 'Create EAN',
    new_ean: 'EAN successfully created',
    new_ean_description: 'Your EAN has sucessfully been added to your profile',
    uploaded_invoices: 'Uploaded invoices',

    // Edit EAN
    edit_ean: 'Edit EAN',

    // Delete EAN
    delete_ean: 'Delete EAN',

    // Profile
    general_information: 'General information',
    title: 'Title',
    mr: 'Mr.',
    mrs: 'Mrs.',
    x: 'X',
    first_name: 'First name',
    last_name: 'Last name',
    phone: 'Phone',
    mobile: 'Mobile',
    date_of_birth: 'Date of birth',
    language: 'Language',
    dutch: 'Dutch',
    french: 'French',
    english: 'English',
    turkish: 'Turkish',
    profile_updated: 'Profile updated',
    profile_updated_description: 'Your profile has successfully been updated',

    address: 'Address',
    street: 'Street',
    number: 'Number',
    addition: 'Addition',
    postal_code: 'Postal code',
    city: 'City',

    notifications: 'Notifications',
    monthly_emails: 'Newsletter',
    sms_notifications: 'SMS notifications',
    marketing_emails: 'Marketing emails',
    newspaper_emails: 'Newspaper emails',

    payment_details: 'Payment details',
    iban: 'IBAN number',
    invoice_email: 'Invoice email address',
    direct_debit: 'I prefer to pay with direct debit',
    electronic_invoice: 'I want to receive electronic invoices',

    mandate: 'Mandate',
    signature_option: 'Signature option',
    opt_out: "Gaele will switch me automatically 14 days after I have received the deal. Gaele will arrange everything so I don't need to worry",
    opt_in: "I prefer to do everything myself. Gaele can't switch me without my permission.",

    account: 'Account',
    deactive_account: 'Deactivate account',

    update_profile: 'Update profile',

    deactivate_account_title: 'Deactivate account',
    deactivate_account_subtitle: 'Are you sure you want to deactivate your profile?',
    deactivate_account_description: "By deactivating your account you won't receive new energy offers from Gaele.",

    // Contracts
    offers: 'Offers',
    offer: 'Offer',
    active_contracts: 'Active contracts',
    expired_contracts: 'Expired contracts',
    active_offers: 'Active offers',
    no_open_offers: 'There are currently no open offers',
    no_active_contracts: 'There are currently no active contracts',
    no_expired_contracts: 'There are currently no expired contracts',
    view_details: 'View details',
    offer_valid_from_till: 'This offer is valid from <1>{{ offer_valid_from }}</1> till <3>{{ offer_valid_till }}</3>',
    accept_offer: 'Accept offer',
    decline_offer: 'Decline offer',
    offer_ref: 'Offer reference',
    energy_type: 'Energy type',
    energy_supplier: 'Energy supplier',
    offer_valid_duration: 'Offer validity',
    contract_valid_duration: 'Contract duration',
    multi: 'Multi (Gas & Electricity)',
    electricity_offer: 'Electricity offer',
    gas_offer: 'Gas offer',
    multi_offer: 'Gas & Electricity offer',

    // Tickets
    tickets: 'Tickets',
    ask_gaele: 'Ask Gaele',
    ask_new_question: 'Ask a new question',
    ask_question_description: 'Our contact managers will help you in any question about us and our system. Feel free to ask about whatever you interested in.',
    ticket_content_title: 'Please describe the problem or question that interests you.',
    ticket_content_subtitle: 'Our managers will try to answer your ticket as soon as possible!',
    state: 'State',
    comments: 'Comments',
    last_activity: 'Last activity',
    my_question: 'My question',
    question: 'Question',
    cancel: 'Cancel',
    submit_question: 'Submit question',
    new_support_ticket: 'New support ticket',
    new_support_ticket_description: 'Thanks for your question. We will get in touch with you soon.',
    add_reply: 'Add reply',
    you: 'You',

    // Contact us
    contact_information: 'Contact information',
    contact_us: 'Contact us',
};
