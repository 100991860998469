import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Button = ({ layout = 'button', color = 'blue', to, label, onClick, children, buttonClasses, containerClasses }) => {
    const { t } = useTranslation();

    // const baseClasses = 'block w-full py-3 px-6 rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2';

    let baseClasses = '';

    if (layout === 'button') {
        baseClasses = 'block w-full py-3 px-6 rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2';
    } else {
        baseClasses = 'block w-auto p-2 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2';
    }

    const getColor = () => {
        if (layout === 'button') {
            switch (color) {
                case 'blue':
                    return 'border border-transparent bg-gaele-light-blue hover:bg-gaele-dark-blue text-white focus:ring-blue-500';
                case 'orange':
                    return 'border border-transparent bg-gaele-light-orange hover:bg-gaele-dark-orange text-white focus:ring-yellow-500';
                case 'gas':
                    return 'border border-transparent bg-gaele-gas text-white focus:ring-blue-500';
                case 'electricity':
                    return 'border border-transparent bg-gaele-electricity text-white focus:ring-yellow-500';
                case 'red':
                    return 'border border-transparent bg-red-50 text-red-400 hover:bg-red-100 focus:ring-red-500';
                case 'green':
                    return 'border border-transparent bg-green-50 text-green-400 hover:bg-green-100 focus:ring-green-500';
                case 'transparant':
                    return 'border border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:ring-gray-300';
                default:
                    return;
            }
        }

        switch (color) {
            case 'blue':
                return 'text-blue-500 focus:ring-blue-500';
            case 'orange':
                return 'text-gaele-dark-orange focus:ring-yellow-500';
            case 'gas':
                return 'text-gaele-gas focus:ring-blue-500';
            case 'electricity':
                return 'text-gaele-electricity focus:ring-yellow-500';
            case 'red':
                return 'text-red-400 hover:bg-red-100 focus:ring-red-500';
            case 'green':
                return 'text-green-400 hover:bg-green-100 focus:ring-green-500';
            case 'transparant':
                return 'text-gray-700 hover:bg-gray-50 focus:ring-gray-300';
            default:
                return;
        }
    };

    if (to) {
        return (
            <div className={containerClasses}>
                <Link to={to} className={`${baseClasses} ${getColor()} ${buttonClasses}`}>
                    {children ? children : t(label)}
                </Link>
            </div>
        );
    }

    return (
        <div className={containerClasses}>
            <button type='button' onClick={onClick} className={`${baseClasses} ${getColor()} ${buttonClasses}`}>
                {children ? children : t(label)}
            </button>
        </div>
    );
};

export default Button;
