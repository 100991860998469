import apiClient from '../utils/apiClient';

export default {
    getEnergyContracts() {
        return apiClient.get(`/contracts`);
    },
    getEnergyContract(id) {
        return apiClient.get(`/contracts/${id}`);
    },
};
