import React from 'react';
import Icon from '@mdi/react';
import { mdiLightbulb, mdiInformation } from '@mdi/js';

const MessageInfoCard = ({ containerClasses = '', contentClasses = '', children }) => {
    const getActionIcon = (type) => {
        switch (type) {
            case 'info':
                return mdiLightbulb;
        }
    };
    return (
        <div className={`relative w-full bg-white border border-grey-100 rounded-lg shadow ${containerClasses}`}>
            <div className={`p-6 ${contentClasses}`}>
                <div className='flex items-center space-x-6'>
                    <span className='text-gaele-light-orange'>
                        <Icon path={mdiLightbulb} size={1.5} />
                    </span>

                    {children}
                </div>
            </div>
        </div>
    );
};

export default MessageInfoCard;
