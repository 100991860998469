import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import useForm from '../../hooks/useForm';
import { AuthContext } from '../../context/AuthContext';
import { NotificationContext } from '../../context/NotificationContext';

import { forgotPasswordValidationSchema } from '../../validators/account';

import Input from '../../components/formElements/Input';
import SubmitButton from '../../components/formElements/SubmitButton';
import authService from '../../services/authService';

const initialValues = {
    email: 'karim@nell-com.be',
};

const ForgotPassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const notificationContext = useContext(NotificationContext);

    const { formData, reset, errors, handleInputChange, handleSubmit } = useForm({
        initialValues: initialValues,
        validationSchema: forgotPasswordValidationSchema,
    });

    const submit = async (values) => {
        await authService.forgotPassword(values);
        notificationContext.addNotification('success', 'check_inbox', 'password_reset_instructions');
        reset();
    };

    return (
        <div className='w-full border border-grey-300 rounded-lg overflow-hidden shadow sm:mx-auto sm:w-full sm:max-w-md'>
            <div className='p-10 bg-white'>
                <div className='space-y-4'>
                    <h2 className='text-sm font-medium tracking-wider uppercase text-gaele-light-blue'>{t('forgot_password')}?</h2>
                </div>
                <form onSubmit={handleSubmit(submit)} className='mt-10 space-y-6'>
                    <Input label='email' type='email' id='email' name='email' value={formData.email} error={errors.email} onChange={handleInputChange} />
                    <SubmitButton label='Send reset instructions' />
                </form>
            </div>
            <div className='p-6 bg-gray-100 border-t border-grey-300 text-center space-y-1'>
                <span className='block text-sm'>
                    {t('already_an_account')}?{' '}
                    <Link to='/login' className='font-medium text-gaele-light-blue'>
                        {t('sign_in')}
                    </Link>
                </span>
                <span className='block text-sm'>
                    {t('no_account')}?{' '}
                    <a href='https://gaele.be' target='_blank' rel='noreferrer' className='font-medium text-gaele-light-blue'>
                        {t('sign_up')}
                    </a>
                </span>
                <span className='block text-sm'>
                    {t('deactivated_account')}?{' '}
                    <Link to='/restore-account' className='font-medium text-gaele-light-blue'>
                        {t('restore')}
                    </Link>
                </span>
            </div>
        </div>
    );
};

export default ForgotPassword;
