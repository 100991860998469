import React, { useContext } from 'react';
import { NotificationContext } from '../../context/NotificationContext';
import NotificationItem from './NotificationItem';

const NotificationList = () => {
    const { notifications, removeNotification } = useContext(NotificationContext);

    return (
        <div aria-live='assertive' className='fixed z-50 inset-0 flex items-start p-6 pointer-events-none'>
            <div className='w-full flex flex-col items-end space-y-4'>
                {notifications.map((notification, i) => (
                    <NotificationItem key={i} removeNotification={removeNotification} {...notification} />
                ))}
            </div>
        </div>
    );
};

export default NotificationList;
