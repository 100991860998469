import React from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import ElectricityIcon from '../../assets/electricity-icon.svg';
import GasIcon from '../../assets/gas-icon.svg';
import Button from '../general/Button';

const EnergyOfferInfoCard = ({ _id, reference, type, offer_valid_from, offer_valid_till, containerClasses }) => {
    const { t } = useTranslation();

    const getIcon = (type) => {
        switch (type) {
            case 'E':
                return <img className='absolute top-5 right-5 w-8 lg:w-12' src={ElectricityIcon} />;
            case 'G':
                return <img className='absolute top-5 right-5 w-8 lg:w-12' src={GasIcon} />;
            case 'M':
                return (
                    <>
                        <img className='absolute top-5 right-12 lg:right-14 w-8 lg:w-10' src={ElectricityIcon} />
                        <img className='absolute top-5 right-5 lg:right-5 w-8 lg:w-10' src={GasIcon} />
                    </>
                );
        }
    };

    const getTitle = (type) => {
        switch (type) {
            case 'E':
                return 'electricity_offer';
            case 'G':
                return 'gas_offer';
            case 'M':
                return 'multi_offer';
        }
    };

    const getTextColor = (type) => {
        switch (type) {
            case 'E':
                return 'text-gaele-electricity';
            case 'G':
                return 'text-gaele-gas';
            case 'M':
                return 'text-gaele-multi';
        }
    };

    const getBorderColor = (type) => {
        switch (type) {
            case 'E':
                return 'border-gaele-electricity';
            case 'G':
                return 'border-gaele-gas';
            case 'M':
                return 'border-gaele-multi';
        }
    };

    return (
        <div className={`relative w-full bg-white border border-grey-100 border-l-0 rounded-lg overflow-hidden ${containerClasses}`}>
            <div className={`border-l-8 ${getBorderColor(type)}`}>
                <div className='relative p-6 lg:p-8'>
                    <div>{getIcon(type)}</div>
                    <div className='space-y-3 lg:space-y-6 pr-10'>
                        <div>
                            <p className={`uppercase tracking-wider text-sm lg:text-base font-medium lg:font-semibold ${getTextColor(type)}`}>
                                <span className='block text-xs lg:text-sm'>#{reference}</span>
                                <span className='block'>{t(getTitle(type))}</span>
                            </p>
                        </div>
                        <div>
                            <p className='text-sm font-light italic'>
                                <Trans i18nKey='offer_valid_from_till' values={{ offer_valid_from: moment(offer_valid_from).format('DD-MM-YYYY'), offer_valid_till: moment(offer_valid_till).format('DD-MM-YYYY') }}>
                                    This offer is valid from <span className='font-medium'>{moment(offer_valid_from).format('DD-MM-YYYY')}</span> till <span className='font-medium'>{moment(offer_valid_till).format('DD-MM-YYYY')}</span>
                                </Trans>
                            </p>
                        </div>
                    </div>
                </div>
                <div className='flex justify-between bg-gray-100 px-6 py-3 lg:px-8 lg:py-4 border-t'>
                    <Button layout='link' color='transparant' to={`/energy-offers/${_id}`}>
                        <span className='font-medium'>{t('view_details')}</span>
                    </Button>
                    <Button layout='link' color='green'>
                        <span className='font-medium'>{t('accept_offer')}</span>
                    </Button>

                    {/* <Button layout='link' color='red'>
                        <span className='font-medium'>{t('decline_offer')}</span>
                    </Button> */}
                </div>
            </div>
        </div>
    );
};

export default EnergyOfferInfoCard;
