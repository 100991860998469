import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Title from '../Title';
import Selectbox from '../../../components/formElements/Selectbox';
import SubmitButton from '../../../components/formElements/SubmitButton';

import { numberOfEanOptions } from '../../../data/eanData';

const SelectEans = ({ step, currentStep, nextStep, formData, errors, handleInputChange }) => {
    const { t } = useTranslation();

    return (
        <section className='space-y-6'>
            <Title step={step} currentStep={currentStep} label={t('select_number_of_eans')} />

            {step === currentStep && (
                <section className='border-0 border-l border-grey-100 pl-6 space-y-6'>
                    <div>
                        <h2 className='text-md font-semibold text-gray-900'>{t('step_two_title')}</h2>
                        <p className='font-light'>{t('step_two_description_one')}</p>
                    </div>

                    <div>
                        <h2 className='text-md font-semibold text-gaele-light-blue'>{t('step_two_description_two')}</h2>
                        <p className='font-base'>{t('step_two_description_three')}</p>
                    </div>

                    <div className='grid grid-cols-12 gap-x-6 gap-y-4'>
                        <div className='col-span-12 md:col-span-6'>
                            <h2>{t('number_of_electricity_eans')}</h2>
                            <Selectbox id='number_of_electricity_eans' name='number_of_electricity_eans' options={numberOfEanOptions} value={formData.number_of_electricity_eans} error={errors.number_of_electricity_eans} onChange={handleInputChange} />
                        </div>

                        <div className='col-span-12 md:col-span-6'>
                            <h2>{t('number_of_gas_eans')}</h2>
                            <Selectbox id='number_of_gas_eans' name='number_of_gas_eans' options={numberOfEanOptions} value={formData.number_of_gas_eans} error={errors.number_of_gas_eans} onChange={handleInputChange} />
                        </div>
                    </div>

                    <div>
                        <p>
                            <Trans i18nKey='eans_colors_description'>
                                Everything that relates to electricity will have a <span className='p-1 bg-gaele-electricity font-medium'>yellow color</span>, and that which relates to gas will be <span className='p-1 bg-gaele-gas text-white font-medium'>blue colored</span>. This will help to structure a large amount of information on your screen.
                            </Trans>
                        </p>
                    </div>

                    <div className='sm:flex sm:gap-4'>
                        <SubmitButton buttonClasses='md:w-auto' label='next_step' />
                    </div>
                </section>
            )}
        </section>
    );
};

export default SelectEans;
