import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/outline';

const ActionInfoCard = ({ actionType }) => {
    return (
        // <div className='bg-white shadow sm:rounded-lg'>
        //     <div className='px-4 py-5 sm:p-6'>
        //         <h3 className='text-lg font-medium leading-6 text-gray-900'>Continuous Integration</h3>
        //         <div className='mt-2 max-w-xl text-sm text-gray-500'>
        //             <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi, totam at reprehenderit maxime aut beatae ad.</p>
        //         </div>
        //         <div className='mt-3 text-sm'>
        //             <a href='#' className='font-medium text-indigo-600 hover:text-indigo-500'>
        //                 Learn more about our CI features
        //                 <span aria-hidden='true'> &rarr;</span>
        //             </a>
        //         </div>
        //     </div>
        // </div>

        <div className='rounded-md bg-blue-50 p-4'>
            <div className='flex'>
                <div className='flex-shrink-0'>
                    <InformationCircleIcon className='h-5 w-5 text-blue-400' aria-hidden='true' />
                </div>
                <div className='ml-3'>
                    <h3 className='text-sm font-medium text-blue-800'>Order completed</h3>
                    <div className='mt-2 text-sm text-blue-700'>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid pariatur, ipsum similique veniam.</p>
                    </div>
                    <div className='mt-4'>
                        <div className='-mx-2 -my-1.5 flex'>
                            <button type='button' className='rounded-md bg-blue-50 px-2 py-1.5 text-sm font-medium text-blue-800 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-blue-50'>
                                Accept offer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActionInfoCard;
