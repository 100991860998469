import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ElectricityIcon from '../../assets/electricity-icon.svg';
import GasIcon from '../../assets/gas-icon.svg';

const ContractInfoCard = ({ _id = undefined, energy_type, containerClasses }) => {
    const { t } = useTranslation();

    const getIcon = (type) => (type === 'G' ? GasIcon : ElectricityIcon);
    const getTitle = (type) => (type === 'G' ? 'Gas contract' : 'Electricity contract');
    const getTextColor = (type) => (type === 'G' ? 'text-gaele-gas' : 'text-gaele-electricity');
    const getBorderColor = (type) => (type === 'G' ? 'border-gaele-gas' : 'border-gaele-electricity');

    return (
        <div className={`relative w-full bg-white border border-grey-100 border-l-0 rounded-lg overflow-hidden ${containerClasses}`}>
            <div className={`border-l-8 ${getBorderColor(energy_type)}`}>
                <div className='relative p-6 lg:p-8'>
                    <img className='absolute top-5 right-5 w-8 lg:w-12' src={getIcon(energy_type)} />
                    <div className='space-y-3 lg:space-y-6'>
                        <div>
                            <p className={`uppercase tracking-wider text-sm lg:text-base font-medium lg:font-semibold ${getTextColor(energy_type)}`}>{getTitle(energy_type)}</p>
                        </div>
                    </div>
                </div>
                <div className='bg-gray-100 px-6 py-3 lg:px-8 lg:py-4 border-t'>
                    <Link to='/contracts/tariffs/'>
                        <span className='font-medium'>{t('view_details')}</span>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ContractInfoCard;
