import apiClient from '../utils/apiClient';
import externalClient from '../utils/externalClient';

export default {
    getUploadUrl(file_name) {
        return apiClient.get(`/invoices/upload?file_name=${file_name}`);
    },
    uploadInvoices(invoices) {
        const formData = new FormData();

        // for (const file of invoices) {
        //     formData.append('invoices', file);
        // }

        return apiClient.post(`/invoices/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
    getInvoices() {
        return apiClient.get(`/invoices`);
    },
    getDownloadLink(invoiceID) {
        return apiClient.get(`invoices/${invoiceID}/download`);
    },
    deleteInvoice(invoiceID) {
        return apiClient.delete(`invoices/${invoiceID}`);
    },
};
