import { useState, useEffect } from 'react';
import * as yup from 'yup';

const useForm = ({ initialValues = {}, validationSchema: schema = yup.object() }) => {
    const [validationSchema, setValidationSchema] = useState(schema);
    const [originalData, setOriginalData] = useState(initialValues);
    const [dataChanged, setDataChanged] = useState(false);
    const [formData, setFormData] = useState(initialValues);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setDataChanged(Object.entries(originalData).toString() !== Object.entries(formData).toString());
    }, [originalData, formData]);

    const handleInputChange = (e) => {
        if (e.target.type === 'file') {
            setFormData((currentValues) => ({ ...currentValues, [e.target.name]: e.target.files }));
        } else if (e.target.type === 'checkbox') {
            setFormData((currentValues) => ({ ...currentValues, [e.target.name]: e.target.checked }));
        } else {
            // const value = !isNaN(e.target.value) && parseInt(e.target.value) ? parseInt(e.target.value) : e.target.value;
            setFormData((currentValues) => ({ ...currentValues, [e.target.name]: e.target.value }));
        }
    };

    const setValue = (name, value) => {
        console.log(name);
        console.log(value);
        setFormData((currentValues) => ({ ...currentValues, [name]: value }));
        console.log(formData);
    };

    const handleSubmit = (onSubmit) => async (e) => {
        e.preventDefault();

        const isFormValid = await validationSchema.isValid(formData, {
            abortEarly: false,
        });

        if (isFormValid) {
            setIsSubmitting(true);
            onSubmit(formData);
        } else {
            await validationSchema.validate(formData, { abortEarly: false }).catch((err) => {
                const errors = err.inner.reduce((acc, error) => {
                    if (error.path) {
                        return {
                            ...acc,
                            [error.path]: error.message,
                        };
                    }
                }, {});

                setErrors(errors);
                setIsSubmitting(false);
            });
        }
    };

    const reset = (data = {}) => {
        setFormData(data);
        setOriginalData(data);
        setErrors({});
    };

    return { formData, errors, isSubmitting, handleInputChange, handleSubmit, dataChanged, reset, setValidationSchema, setValue };
};

export default useForm;
