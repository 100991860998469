import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NotificationContext } from '../../context/NotificationContext';
import ticketService from '../../services/ticketService';
import Page from '../../components/layoutParts/Page';
import MessageInfoCard from '../../components/cards/MessageInfoCard';

import Card from '../../components/cards/BaseCard';
import Button from '../../components/general/Button';
import BaseModal from '../../components/modals/BaseModal';

import useForm from '../../hooks/useForm';
import Input from '../../components/formElements/Input';
import Textarea from '../../components/formElements/Textarea';
import SubmitButton from '../../components/formElements/SubmitButton';
import { createSupportTicketValidationSchema } from '../../validators/ticket';

const initialValues = {
    title: '',
    message: '',
};

const Overview = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [tickets, setTickets] = useState([]);
    const notificationContext = useContext(NotificationContext);
    const [questionModalOpen, setQuestionModalOpen] = useState(searchParams.get('ask_question') === 'true' ? true : false);

    useEffect(() => {
        getTickets();
    }, []);

    const getTickets = async () => {
        const { data } = await ticketService.getTickets();
        setTickets(data.results);
    };

    const { formData, errors, handleInputChange, handleSubmit, reset } = useForm({
        initialValues: initialValues,
        validationSchema: createSupportTicketValidationSchema,
    });

    const submit = async (values) => {
        await ticketService.createTicket(values);

        notificationContext.addNotification('success', 'new_support_ticket', 'new_support_ticket_description');
        reset();
        setQuestionModalOpen(false);
        getTickets();
    };

    const openQuestionModal = () => setQuestionModalOpen(true);
    const closeQuestionModal = () => setQuestionModalOpen(false);

    return (
        <Page title='ask_gaele'>
            <section className='space-y-6'>
                <MessageInfoCard>
                    <h2 className='text-lg font-medium text-gray-900'>{t('ask_question')}</h2>
                    <p className='text-base font-light text-gray-700'>{t('ask_question_description')}</p>
                </MessageInfoCard>

                {(tickets.length || []) > 0 && (
                    <Card>
                        <table className='min-w-full'>
                            <thead>
                                <tr>
                                    <th scope='col' className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'>
                                        {t('title')}
                                    </th>
                                    <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                                        {t('state')}
                                    </th>
                                    <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                                        {t('comments')}
                                    </th>
                                    <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                                        {t('last_activity')}
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='divide-y divide-gray-200'>
                                {tickets.map((ticket, i) => (
                                    <tr key={i} className='cursor-pointer hover:bg-gray-50' onClick={() => navigate(`/tickets/${ticket._id}`)}>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>{ticket.title}</td>
                                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>{ticket.is_active ? 'open' : 'closed'}</td>
                                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>{ticket.message_count}</td>
                                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>{moment(ticket.last_activity).format('DD/MM/YYYY')}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Card>
                )}

                <Button buttonClasses='md:w-auto' color='orange' label='ask_new_question' onClick={openQuestionModal} />

                <BaseModal isOpen={questionModalOpen} closeModal={closeQuestionModal}>
                    <form onSubmit={handleSubmit(submit)}>
                        <div className='p-6 space-y-6'>
                            <h3 className='text-2xl font-medium leading-6 text-gray-900'>{t('my_question')}</h3>

                            <div>
                                <p className='text-sm text-gray-900 font-semibold'>{t('ticket_content_title')}</p>
                                <p className='text-sm text-gray-500'>{t('ticket_content_subtitle')}</p>
                            </div>

                            <div className='space-y-6'>
                                <Input label='title' type='text' id='title' name='title' value={formData.title} error={errors.title} onChange={handleInputChange} />
                                <Textarea label='question' type='text' id='message' name='message' value={formData.message} error={errors.message} onChange={handleInputChange} />
                            </div>
                        </div>
                        <div className='p-6 bg-gray-50 sm:flex sm:flex-row-reverse sm:gap-4'>
                            <SubmitButton label='submit_question' />
                            <Button containerClasses='mt-3 sm:mt-0' type='button' color='transparant' label='cancel' onClick={closeQuestionModal} />
                        </div>
                    </form>
                </BaseModal>
            </section>
        </Page>
    );
};

export default Overview;
