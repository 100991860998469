import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { NotificationProvider } from './context/NotificationContext';
import { AuthProvider } from './context/AuthContext';
import NotificationList from './components/notifications/NotificationList';
import Interceptor from './utils/interceptor';
import Router from './router';

const App = () => (
    <BrowserRouter>
        <AuthProvider>
            <NotificationProvider>
                <Interceptor>
                    <NotificationList />
                    <Router />
                </Interceptor>
            </NotificationProvider>
        </AuthProvider>
    </BrowserRouter>
);

export default App;
