import React from 'react';
import { useTranslation } from 'react-i18next';
import Logo from './../../assets/logo.svg';
import Girl from './../../assets/girl.png';

const GuestSidebar = () => {
    const { t } = useTranslation();

    return (
        <aside className='relative hidden lg:block pt-24 px-10 pb-6 overflow-y-auto w-4/12 md:max-w-md bg-gradient-to-b from-gaele-light-blue to-gaele-dark-blue'>
            <div className='flex flex-col h-full justify-between'>
                <div className='flex flex-col justify-center space-y-6'>
                    <img src={Logo} alt='Gaele logo' className='h-24 w-auto' />
                    <h1 className='text-center text-white font-bold text-lg'>{t('platform_tagline')}</h1>
                </div>

                <div>
                    <p className='relative text-right text-white font-medium text-md z-10'>
                        <span className='block'>
                            {t('platform_street')} {t('platform_house_number')}, {t('platform_postal_code')} {t('platform_city')}
                        </span>
                        <span className='block'>&copy; 2017 - {new Date().getFullYear()} Gaele</span>
                    </p>
                    <img src={Girl} alt='Gaele avatar' className='absolute bottom-0 w-full' />
                </div>
            </div>
        </aside>
    );
};

export default GuestSidebar;
