import apiClient from '../utils/apiClient';

export default {
    getProfileInfo() {
        return apiClient.get(`/profile`);
    },
    updateProfile(data) {
        return apiClient.put('/profile', data);
    },
    deactivateAccount() {
        return apiClient.get('/profile/deactivate');
    },
};
