import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { RadioGroup } from '@headlessui/react';

import Title from '../Title';
import SubmitButton from '../../../components/formElements/SubmitButton';

const Finish = ({ step, currentStep, formData, handleInputChange }) => {
    const { t } = useTranslation();
    const [type, setType] = useState('auto');

    useEffect(() => {
        handleInputChange({
            target: {
                name: 'energy_mandate',
                value: type,
            },
        });
    }, [type]);

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ');
    }

    return (
        <section className='space-y-6'>
            <Title step={step} currentStep={currentStep} label={t('complete')} />

            {step === currentStep && (
                <section className='border-0 border-l border-grey-100 pl-6 space-y-6'>
                    <div>
                        <h2 className='text-md font-semibold text-gaele-light-blue'>Well done and thanks!</h2>
                        <p className='font-light'>Your registration is completed.</p>
                    </div>

                    <div>
                        <h2 className='text-md font-semibold text-gaele-light-blue'>What happens next?</h2>
                        <p className='font-base'>
                            <span className='font-semibold'>Gaele continually monitors</span> the energy price and <span className='font-semibold'>gives you an advantageous proposal</span> at the ideal moment for you. Gaele then gives you 14 days to refuse the proposal. The exchange to the new suppliers is activated without notice. Gaele arranges your administration free of charge and ensures the transfer.{' '}
                            <span className='font-semibold'>You can always view and modify your details at &apos;My profile&apos; page.</span>
                        </p>
                    </div>

                    <div>
                        <RadioGroup value={type} onChange={setType}>
                            <div className='grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4'>
                                <RadioGroup.Option key={1} value='auto' className={({ checked, active }) => classNames(checked ? 'border-transparent' : 'border-gray-300', active ? 'border-indigo-500 ring-2 ring-indigo-500' : '', 'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none')}>
                                    {({ active, checked }) => (
                                        <>
                                            <RadioGroup.Description as='div' className='flex items-center px-4 py-2 text-center'>
                                                <p className='text-sm text-gray-800'>
                                                    <span className='font-semibold'>Gaele will switch you automatically</span> to the best offer on the market based on your current tariffs, contract will start automatically. Gaele will arrange everything so you don&apos;t need to worry.
                                                </p>
                                            </RadioGroup.Description>
                                            <span className={classNames(active ? 'border' : 'border-2', checked ? 'border-indigo-500' : 'border-transparent', 'absolute -inset-px rounded-lg pointer-events-none')} aria-hidden='true' />
                                        </>
                                    )}
                                </RadioGroup.Option>
                                <RadioGroup.Option key={2} value='manual' className={({ checked, active }) => classNames(checked ? 'border-transparent' : 'border-gray-300', active ? 'border-indigo-500 ring-2 ring-indigo-500' : '', 'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none')}>
                                    {({ active, checked }) => (
                                        <>
                                            <RadioGroup.Description as='div' className='flex items-center px-4 py-2 text-center'>
                                                <p className='text-sm text-gray-800'>I prefer to arrange everything myself and choose a proposal to make the switch. Gaele cannot switch me without permission.</p>
                                            </RadioGroup.Description>
                                            <span className={classNames(active ? 'border' : 'border-2', checked ? 'border-indigo-500' : 'border-transparent', 'absolute -inset-px rounded-lg pointer-events-none')} aria-hidden='true' />
                                        </>
                                    )}
                                </RadioGroup.Option>
                            </div>
                        </RadioGroup>
                    </div>

                    <div className='sm:flex sm:gap-4'>
                        <SubmitButton buttonClasses='md:w-auto' label='complete' />
                    </div>
                </section>
            )}
        </section>
    );
};

export default Finish;

// import React from 'react';
// import { useTranslation } from 'react-i18next';
// import { RadioGroup } from '@headlessui/react';

// import useForm from '../../../hooks/useForm';

// import Title from '../Title';
// import Button from '../../../components/general/Button';
// import Selectbox from '../../../components/formElements/Selectbox';

// const Finish = ({ step, currentStep, nextStep }) => {
//     const { t } = useTranslation();

//     function classNames(...classes) {
//         return classes.filter(Boolean).join(' ');
//     }

//     return (
//         <section className='space-y-6'>
//             <Title step={step} currentStep={currentStep} label={t('finish')} />

//             {step === currentStep && (
//                 <section className='border-0 border-l border-grey-100 pl-6 space-y-6'>
//                     <div>
//                         <h2 className='text-md font-semibold text-gaele-light-blue'>Well done and thanks!</h2>
//                         <p className='font-light'>Your registration is completed.</p>
//                     </div>

//                     <div>
//                         <h2 className='text-md font-semibold text-gaele-light-blue'>What happens next?</h2>
//                         <p className='font-base'>
//                             <span className='font-semibold'>Gaele continually monitors</span> the energy price and <span className='font-semibold'>gives you an advantageous proposal</span> at the ideal moment for you. Gaele then gives you 14 days to refuse the proposal. The exchange to the new suppliers is activated without notice. Gaele arranges your administration free of charge and ensures the transfer.{' '}
//                             <span className='font-semibold'>You can always view and modify your details at &apos;My profile&apos; page.</span>
//                         </p>
//                     </div>

//                     <div>
//                         <RadioGroup value={type} onChange={setType}>
//                             <div className='grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4'>
//                                 <RadioGroup.Option key={1} value='auto' className={({ checked, active }) => classNames(checked ? 'border-transparent' : 'border-gray-300', active ? 'border-indigo-500 ring-2 ring-indigo-500' : '', 'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none')}>
//                                     {({ active, checked }) => (
//                                         <>
//                                             <RadioGroup.Description as='div' className='flex items-center px-4 py-2 text-center'>
//                                                 <p className='text-sm text-gray-800'>
//                                                     <span className='font-semibold'>Gaele will switch you automatically</span> to the best offer on the market based on your current tariffs, contract will start automatically. Gaele will arrange everything so you don&apos;t need to worry.
//                                                 </p>
//                                             </RadioGroup.Description>
//                                             <span className={classNames(active ? 'border' : 'border-2', checked ? 'border-indigo-500' : 'border-transparent', 'absolute -inset-px rounded-lg pointer-events-none')} aria-hidden='true' />
//                                         </>
//                                     )}
//                                 </RadioGroup.Option>
//                                 <RadioGroup.Option key={2} value='manual' className={({ checked, active }) => classNames(checked ? 'border-transparent' : 'border-gray-300', active ? 'border-indigo-500 ring-2 ring-indigo-500' : '', 'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none')}>
//                                     {({ active, checked }) => (
//                                         <>
//                                             <RadioGroup.Description as='div' className='flex items-center px-4 py-2 text-center'>
//                                                 <p className='text-sm text-gray-800'>I prefer to arrange everything myself and choose a proposal to make the switch. Gaele cannot switch me without permission.</p>
//                                             </RadioGroup.Description>
//                                             <span className={classNames(active ? 'border' : 'border-2', checked ? 'border-indigo-500' : 'border-transparent', 'absolute -inset-px rounded-lg pointer-events-none')} aria-hidden='true' />
//                                         </>
//                                     )}
//                                 </RadioGroup.Option>
//                             </div>
//                         </RadioGroup>
//                     </div>

//                     <div className='sm:flex sm:gap-4'>
//                         <Button type='button' label='next_step' onClick={nextStep} />
//                     </div>
//                 </section>
//             )}
//         </section>
//     );
// };

// export default Finish;

// const Complete = ({ values, handleChange, prevStep, nextStep }) => {
//     const [type, setType] = useState(values.gaele_type);

//     useEffect(() => {
//         handleChange({
//             target: {
//                 name: 'gaele_type',
//                 value: type,
//             },
//         });
//     }, [type]);

//     function classNames(...classes) {
//         return classes.filter(Boolean).join(' ');
//     }

//     return (
//         <section className='space-y-6'>
//             <div>
//                 <h2 className='text-md font-semibold text-gaele-light-blue'>Well done and thanks!</h2>
//                 <p className='font-light'>Your registration is completed.</p>
//             </div>

//             <div>
//                 <h2 className='text-md font-semibold text-gaele-light-blue'>What happens next?</h2>
//                 <p className='font-base'>
//                     <span className='font-semibold'>Gaele continually monitors</span> the energy price and <span className='font-semibold'>gives you an advantageous proposal</span> at the ideal moment for you. Gaele then gives you 14 days to refuse the proposal. The exchange to the new suppliers is activated without notice. Gaele arranges your administration free of charge and ensures the transfer.{' '}
//                     <span className='font-semibold'>You can always view and modify your details at &apos;My profile&apos; page.</span>
//                 </p>
//             </div>

//             <div>
//                 <RadioGroup value={type} onChange={setType}>
//                     <div className='grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4'>
//                         <RadioGroup.Option key={1} value='auto' className={({ checked, active }) => classNames(checked ? 'border-transparent' : 'border-gray-300', active ? 'border-indigo-500 ring-2 ring-indigo-500' : '', 'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none')}>
//                             {({ active, checked }) => (
//                                 <>
//                                     <RadioGroup.Description as='div' className='flex items-center px-4 py-2 text-center'>
//                                         <p className='text-sm text-gray-800'>
//                                             <span className='font-semibold'>Gaele will switch you automatically</span> to the best offer on the market based on your current tariffs, contract will start automatically. Gaele will arrange everything so you don&apos;t need to worry.
//                                         </p>
//                                     </RadioGroup.Description>
//                                     <span className={classNames(active ? 'border' : 'border-2', checked ? 'border-indigo-500' : 'border-transparent', 'absolute -inset-px rounded-lg pointer-events-none')} aria-hidden='true' />
//                                 </>
//                             )}
//                         </RadioGroup.Option>
//                         <RadioGroup.Option key={2} value='manual' className={({ checked, active }) => classNames(checked ? 'border-transparent' : 'border-gray-300', active ? 'border-indigo-500 ring-2 ring-indigo-500' : '', 'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none')}>
//                             {({ active, checked }) => (
//                                 <>
//                                     <RadioGroup.Description as='div' className='flex items-center px-4 py-2 text-center'>
//                                         <p className='text-sm text-gray-800'>I prefer to arrange everything myself and choose a proposal to make the switch. Gaele cannot switch me without permission.</p>
//                                     </RadioGroup.Description>
//                                     <span className={classNames(active ? 'border' : 'border-2', checked ? 'border-indigo-500' : 'border-transparent', 'absolute -inset-px rounded-lg pointer-events-none')} aria-hidden='true' />
//                                 </>
//                             )}
//                         </RadioGroup.Option>
//                     </div>
//                 </RadioGroup>
//             </div>

//             <div className='flex space-x-6'>
//                 <Button type='secondary' onClick={prevStep}>
//                     Previous step
//                 </Button>
//                 <Button onClick={nextStep}>Finish onboarding</Button>
//             </div>
//         </section>
//     );
// };
