import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import useResizeHook from '../hooks/useResizeHook';
import AppSidebar from '../components/layoutParts/AppSidebar';
import MobileHeader from './../components/layoutParts/MobileHeader';

const AppLayout = ({ disabled }) => {
    const size = useResizeHook();
    const [sidebarOpen, setSidebarOpen] = useState(false);

    useEffect(() => {
        setSidebarOpen(false);
    }, [size]);

    return (
        <div className='relative w-screen h-screen'>
            <div className='flex flex-col w-full h-full'>
                {/* Mobile Header */}
                <MobileHeader setSidebarOpen={setSidebarOpen} />

                {/* Page */}
                <div className='flex flex-row flex-1'>
                    <AppSidebar disabled={disabled} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    <div className='flex flex-col flex-1 bg-gaele-mid-grey'>
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppLayout;
