import apiClient from '../utils/apiClient';

export default {
    getEans() {
        return apiClient.get(`/eans`);
    },
    getEan(id) {
        return apiClient.get(`/eans/${id}`);
    },
    createEan(ean) {
        return apiClient.post(`/eans`, ean);
    },
    updateEan(id, ean) {
        return apiClient.put(`/eans/${id}`, ean);
    },
    deleteEan(id) {
        return apiClient.delete(`/eans/${id}`);
    },
    getEnergySuppliers() {
        return apiClient.get(`/energy-suppliers`);
    },
};
