export default {
    required_field: 'This is a required field',
    invalid_email: 'Invalid email address',

    network_error: 'Network error',
    gateway_timeout: "We didn't get a response from the server. Please try again in a few moments",
    server_down: 'The server may be down at this moment. Please try again in a few moments.',

    title_too_short: 'Title is too short (min. 5 characters)',
    message_too_short: 'Your message is too short (min. 30 characters)',

    oops: 'Oops',
    something_went_wrong: 'Something went wrong',

    invalid_ean: 'Invalid EAN',

    code_6_numbers: 'Code should be 6 numbers',
};
