import * as yup from 'yup';

export const loginValidationSchema = yup.object().shape({
    email: yup.string().required('required_field').email('invalid_email'),
    password: yup.string().required('required_field'),
});

export const forgotPasswordValidationSchema = yup.object().shape({
    email: yup.string().required('required_field').email('invalid_email'),
});

export const resetPasswordValidationSchema = yup.object().shape({
    password: yup.string().required('required_field'),
    password_confirmation: yup
        .string()
        .required('required_field')
        .oneOf([yup.ref('password'), null], 'passwords_not_matching'),
});

export const verifyAccountValidationSchema = yup.object().shape({
    password: yup.string().required('required_field'),
    password_confirmation: yup
        .string()
        .required('required_field')
        .oneOf([yup.ref('password'), null], 'passwords_not_matching'),
});
