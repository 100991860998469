import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import MobileHeader from '../components/layoutParts/MobileHeader';
import GuestSidebar from '../components/layoutParts/GuestSidebar';

const GuestLayout = ({ children }) => {
    const { t } = useTranslation();

    return (
        <div className='relative w-screen h-screen'>
            <div className='flex flex-col w-full h-full'>
                {/* Mobile Header */}
                <MobileHeader />

                {/* Page */}
                <div className='flex flex-row flex-1'>
                    <GuestSidebar />
                    <div className='flex-1 p-4 bg-cover bg-center bg-[url("./../assets/blur.png")]'>
                        <div className='h-full flex flex-col justify-between'>
                            <div />

                            <div className='w-full h-full flex items-center'>
                                <Outlet />
                            </div>

                            <div className='block lg:hidden'>
                                <p className='text-center text-white font-medium text-sm'>
                                    <span className='block'>
                                        {t('platform_street')} {t('platform_house_number')}, {t('platform_postal_code')} {t('platform_city')}
                                    </span>
                                    <span className='block'>&copy; 2017 - {new Date().getFullYear()} Gaele</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GuestLayout;
