import React, { useContext } from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';

import { AuthContext } from '../context/AuthContext';

import Spinner from '../components/general/Spinner';

import GuestLayout from '../layouts/GuestLayout';
import AppLayout from '../layouts/AppLayout';

import Login from '../pages/account/Login';
import ForgotPassword from '../pages/account/ForgotPassword';
// import ResetPassword from '../pages/account/ResetPassword';
import VerifyAccount from '../pages/account/VerifyAccount';

import SignMandate from '../pages/mandate/SignMandate';

import Onboarding from '../pages/onboarding/Overview';

import ProfileOverview from '../pages/profile/Overview';
import EanOverview from '../pages/eans/Overview';
import EanDetails from '../pages/eans/Details';
import AddEan from '../pages/eans/Add';
import ContractOverview from '../pages/contracts/Overview';
// import EnergyOfferDetails from '../pages/contracts/EnergyOfferDetails';
import TicketOverview from '../pages/tickets/Overview';
import TicketDetails from '../pages/tickets/Details';
import ContactUs from '../pages/contact/ContactUs';

import NotFound from '../pages/general/NotFound';
import EnergyOfferDetail from '../pages/contracts/EnergyOfferDetail';

const Router = () => {
    const authContext = useContext(AuthContext);

    const GuestRoute = () => {
        return !authContext.isAuthenticated ? <Outlet /> : <Navigate to='/' replace />;
    };

    const ProtectedRoutes = () => {
        return authContext.isAuthenticated ? <Outlet /> : <Navigate to='/login' replace />;
    };

    if (authContext.isAuthenticated === null) {
        return <Spinner />;
    }

    return (
        <Routes>
            <Route element={<GuestRoute />}>
                <Route element={<GuestLayout />}>
                    <Route path='/login' element={<Login />} />
                    <Route path='/forgot-password' element={<ForgotPassword />} />
                    <Route path='/verify-account' element={<VerifyAccount />} />
                </Route>
            </Route>

            <Route element={<ProtectedRoutes />}>
                <Route element={<AppLayout disabled />}>
                    <Route path='/mandate/sign' element={<SignMandate />} />
                </Route>

                <Route element={<AppLayout disabled />}>
                    <Route path='/onboarding' element={<Onboarding />} />
                </Route>

                <Route element={<AppLayout />}>
                    <Route path='/' element={<Navigate to='/profile' replace />} />
                    <Route path='/profile' element={<ProfileOverview />} />
                    <Route path='/eans' element={<EanOverview />} />
                    <Route path='/eans/add' element={<AddEan />} />
                    <Route path='/eans/:id' element={<EanDetails />} />
                    <Route path='/tickets' element={<TicketOverview />} />
                    <Route path='/tickets/:id' element={<TicketDetails />} />
                    <Route path='/contracts' element={<ContractOverview />} />
                    <Route path='/energy-offers/:id' element={<EnergyOfferDetail />} />
                    <Route path='/contact-us' element={<ContactUs />} />
                </Route>

                <Route path='404' element={<NotFound />} />
                <Route path='*' element={<Navigate to='/404' replace />} />
            </Route>
        </Routes>
    );
};

export default Router;
