import React, { useContext, useMemo } from 'react';
import { NotificationContext } from '../context/NotificationContext';
import apiClient from './apiClient';

const Interceptor = ({ children }) => {
    const { addNotification } = useContext(NotificationContext);

    useMemo(() => {
        apiClient.interceptors.request.use(
            (config) => {
                return config;
            },
            (error) => {
                // console.log('Ik log de request error: ', error);
                return Promise.reject(error);
            },
        );

        apiClient.interceptors.response.use(
            (response) => {
                if (response.data) {
                    // return success
                    if (response.status === 200 || response.status === 201) {
                        return response;
                    }
                    // reject errors & warnings
                    addNotification('error', 'oops', 'something_went_wrong');
                    return Promise.reject(response);
                }

                // default fallback
                return Promise.reject(response);
            },
            (error) => {
                if (error.message === 'Network Error') {
                    if (error?.response?.status === 504) {
                        addNotification('error', 'network_error', 'gateway_timeout');
                    } else {
                        addNotification('error', 'network_error', 'server_down');
                    }
                } else {
                    console.log('Wat is deze error?');
                    console.log(error);
                    // if the server throws an error (404, 500 etc.)
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                    // console.log(error.response.data.message);

                    if (error?.response?.config?.url !== '/me') {
                        addNotification('error', 'oops', error.response.data.message);
                    }
                }
                return Promise.reject(error);
            },

            // (response) => {
            //     return Promise.resolve(response);
            // },
            // (error) => {
            //     if (!error.response) {
            //         addNotification('error', 'network_error', 'network_error');
            //         // network error
            //         // console.log('Network error');
            //     } else {
            //         const response = error.response.data;
            //         console.log('Errorrrr: ', error.response);
            //         addNotification('error', 'test', 'test');
            //     }

            //     return Promise.reject(error);
            // },
        );
    }, [addNotification]);

    return <>{children}</>;
};

export default Interceptor;
