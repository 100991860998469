import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ElectricityIcon from '../../assets/electricity-icon.svg';
import ElectricityIconGrey from '../../assets/electricity-icon-grey.svg';
import GasIcon from '../../assets/gas-icon.svg';
import GasIconGrey from '../../assets/gas-icon-grey.svg';
import Button from '../../components/general/Button';

const EanInfoCard = ({ _id, ean_code, ean_type, street, house_number, addition, postal_code, city, is_complete, containerClasses, deleteEan }) => {
    const { t } = useTranslation();

    const getIcon = (type) => (type === 'G' ? (!is_complete ? GasIconGrey : GasIcon) : !is_complete ? ElectricityIconGrey : ElectricityIcon);
    const getTitle = (type) => (type === 'G' ? 'gas' : 'electricity');
    const getTextColor = (type) => (!is_complete ? 'text-gray-500' : type === 'G' ? 'text-gaele-gas' : 'text-gaele-electricity');
    const getBorderColor = (type) => (!is_complete ? 'border-gray-500' : type === 'G' ? 'border-gaele-gas' : 'border-gaele-electricity');
    const getAddress = (street, house_number, addition, postal_code, city) => {
        let address = '';

        if (street) address += street;
        if (house_number) address += ` ${house_number}`;
        if (addition) address += ` ${addition}`;

        if (postal_code) address == '' ? (address += postal_code) : (address += `, ${postal_code}`);
        if (city) address += ` ${city}`;

        return address == '' ? '-' : address;
    };

    return (
        <div className={`relative w-full bg-white border border-grey-100 border-l-0 rounded-lg overflow-hidden ${containerClasses}`}>
            <div className={`border-l-8 ${getBorderColor(ean_type)}`}>
                <div className='relative p-6 lg:p-8'>
                    <img className='absolute top-5 right-5 w-8 lg:w-12' src={getIcon(ean_type)} />
                    <div className='space-y-3 lg:space-y-6'>
                        <div>
                            <p className={`uppercase tracking-wider text-sm lg:text-base font-medium lg:font-semibold ${getTextColor(ean_type)}`}>{getTitle(ean_type)}</p>
                        </div>
                        <div>
                            <p className='text-xl lg:text-3xl font-light'>EAN {ean_code}</p>
                        </div>
                        <div>
                            <p className='text-sm lg:text-lg font-medium lg:font-semibold'>{t('ean_location')}:</p>
                            <p className='text-sm lg:text-base font-light'>{getAddress(street, house_number, addition, postal_code, city)}</p>
                        </div>
                    </div>
                </div>
                <div className='flex justify-between bg-gray-100 px-6 py-3 lg:px-8 lg:py-4 border-t'>
                    <Button layout='link' color='transparant' to={`/eans/${_id}`}>
                        <span className='font-medium'>{t('view_details')}</span>
                    </Button>
                    <Button layout='link' color='red' onClick={() => deleteEan(_id)}>
                        <span className='font-medium'>{t('delete_ean')}</span>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default EanInfoCard;
