export const profileTypes = [
    { label: 'private person', value: 'b2c' },
    { label: 'company', value: 'b2b' },
];

export const profileTitles = [
    { label: 'mr', value: 'mr' },
    { label: 'mrs', value: 'mrs' },
    { label: 'x', value: 'x' },
];
