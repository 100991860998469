import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NotificationContext } from '../../context/NotificationContext';
import { AuthContext } from '../../context/AuthContext';

import Page from '../../components/layoutParts/Page';
import ticketService from '../../services/ticketService';
import Card from '../../components/cards/BaseCard';
import useForm from '../../hooks/useForm';
import Textarea from '../../components/formElements/Textarea';
import SubmitButton from '../../components/formElements/SubmitButton';

import { addMessageToTicketValidationSchema } from '../../validators/ticket';

const Details = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [ticket, setTicket] = useState({});
    const { user } = useContext(AuthContext);
    const notificationContext = useContext(NotificationContext);

    const { formData, errors, handleInputChange, handleSubmit, reset } = useForm({
        initialValues: {
            message: '',
        },
        validationSchema: addMessageToTicketValidationSchema,
    });

    const submit = async (values) => {
        const { data } = await ticketService.addMessageToTicket(id, values);
        notificationContext.addNotification('success', 'new_support_ticket', 'new_support_ticket_description');
        setTicket((currentTicket) => ({ ...currentTicket, conversation: [...currentTicket.conversation, data] }));
        reset();
    };

    useEffect(() => {
        fetchTicket(id);
    }, []);

    const fetchTicket = async (id) => {
        const { data } = await ticketService.getTicket(id);
        setTicket(data);
    };

    return (
        <Page title={`Ticket #${ticket.ref}`}>
            <Card>
                <h1 className='text-md font-bold'>{ticket.title}</h1>

                <div className='mt-2 grid grid-cols-1 divide-y'>
                    {ticket?.conversation?.map(({ created_by, created_at, content }, i) => (
                        <div className='py-4' key={i}>
                            {i > 0 && (
                                <header>
                                    <span className='text-gray-500 font-bold text-xs'>{t('you')}</span> <span className='text-gray-400 text-xs'>({moment(created_at).format('DD-MM-YYYY HH:mm')})</span>
                                </header>
                            )}

                            <p className='whitespace-pre-line'>{content}</p>
                        </div>
                    ))}
                </div>
            </Card>

            <form onSubmit={handleSubmit(submit)} className='mt-6'>
                <div className='space-y-6'>
                    <Textarea label='add_reply' type='text' id='message' name='message' value={formData.message} error={errors.message} onChange={handleInputChange} />
                    <SubmitButton className='md:max-w-xs' label='Save message' />
                </div>
            </form>
        </Page>
    );
};

export default Details;
