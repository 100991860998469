export const numberOfEanOptions = [
    { label: 0, value: 0 },
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
];

export const eanTypes = [
    { label: 'electricity', value: 'E' },
    { label: 'gas', value: 'G' },
];

export const eanReasons = [
    { label: 'switch', value: 'switch' },
    { label: 'new_meter', value: 'new_meter' },
    { label: 'reopening', value: 'reopening' },
    { label: 'supplier_drop', value: 'supplier_drop' },
    { label: 'moving', value: 'moving' },
];

export const eanMeterCheckPeriods = [
    { label: 'january', value: 'january' },
    { label: 'february', value: 'february' },
    { label: 'march', value: 'march' },
    { label: 'april', value: 'april' },
    { label: 'may', value: 'may' },
    { label: 'june', value: 'june' },
    { label: 'july', value: 'july' },
    { label: 'august', value: 'august' },
    { label: 'september', value: 'september' },
    { label: 'october', value: 'october' },
    { label: 'november', value: 'november' },
    { label: 'december', value: 'december' },
];

export const eanMeterTypes = [
    { label: 'ymr', value: 'YMR' },
    { label: 'mmr', value: 'MMR' },
    { label: 'amr', value: 'AMR' },
    { label: 'smr', value: 'SMR' },
];
