import React from 'react';
import { useTranslation } from 'react-i18next';

import Title from '../Title';
import SubmitButton from '../../../components/formElements/SubmitButton';

const Intro = ({ step, currentStep, nextStep }) => {
    const { t } = useTranslation();

    return (
        <section className='space-y-6'>
            <Title step={step} currentStep={currentStep} label={t('intro')} />

            {step === currentStep && (
                <section className='border-0 border-l border-grey-100 pl-6 space-y-6'>
                    <div>
                        <h2 className='text-md font-semibold text-gaele-light-blue'>{t('intro_title')}</h2>
                        <p className='font-light'>{t('intro_description_one')}</p>
                        <p className='font-base'>{t('intro_description_two')}</p>
                    </div>

                    <div>
                        <h2 className='text-md font-semibold text-gaele-light-blue'>{t('intro_description_three')}</h2>
                        <p className='font-base'>{t('intro_description_four')}</p>
                    </div>

                    <div>
                        <SubmitButton buttonClasses='md:w-auto' label='next_step' />
                    </div>
                </section>
            )}
        </section>
    );
};

export default Intro;
