import React, { useState, useEffect } from 'react';
import Icon from '@mdi/react';
import { mdiFileEdit, mdiFileCheck, mdiFileRemove } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import Card from '../../components/cards/BaseCard';
import ContractInfoCard from '../../components/cards/ContractInfoCard';
import Page from '../../components/layoutParts/Page';
import energyContractService from '../../services/energyContractService';
import energyOfferService from '../../services/energyOfferService';
import EnergyOfferInfoCard from '../../components/cards/EnergyOfferInfoCard';
import axios from 'axios';

const Overview = () => {
    const { t } = useTranslation();
    const [energyContracts, setEnergyContracts] = useState([]);
    const [energyOffers, setEnergyOffers] = useState([]);

    useEffect(() => {
        getEnergyContracts();
        getEnergyOffers();
    }, []);

    const getEnergyContracts = async () => {
        const { data } = await energyContractService.getEnergyContracts();
        console.log(data.results);
        setEnergyContracts(data.results);
    };

    const getEnergyOffers = async () => {
        const { data } = await energyOfferService.getEnergyOffers();
        // console.log(data);
        setEnergyOffers(data.energy_offers);
    };

    return (
        <Page title='contracts'>
            <section className='space-y-6'>
                <Card contentClasses='space-y-6'>
                    <header className='flex items-center space-x-2'>
                        <span className='text-yellow-500'>
                            <Icon path={mdiFileEdit} size={1} />
                        </span>
                        <h2 className='text-2xl font-medium'>
                            {t('active_offers')} {energyOffers.length > 0 && `(${energyOffers.length})`}
                        </h2>
                    </header>

                    {energyOffers.length > 0 ? (
                        <div className='grid grid-cols-12 gap-x-10 gap-y-4'>
                            {energyOffers.map((offer, i) => (
                                <div className='col-span-12 max-w-md 2xl:col-span-6 2xl:max-w-none' key={i}>
                                    <EnergyOfferInfoCard key={i} {...offer} />
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p className='text-sm font-light text-gray-500'>{t('no_open_offers')}</p>
                    )}

                    {/* <div className='grid grid-cols-12 gap-x-10 gap-y-4'> */}
                    {/* 
                        {energyOffers.map((offer, i) => (
                            <div className='col-span-12 max-w-md 2xl:col-span-6 2xl:max-w-none' key={i}>
                                <EnergyOfferInfoCard key={i} {...offer} />
                            </div>
                        ))} */}
                    {/* {eans.map((ean, i) => (
                        <Link className='col-span-12 lg:col-span-6 block max-w-md lg:max-w-xl' key={i} to={`/eans/${ean._id}`}>
                            <EanInfoCard {...ean} />
                        </Link>
                    ))} */}
                    {/* </div> */}
                </Card>

                <Card contentClasses='space-y-6'>
                    <header className='flex items-center space-x-2'>
                        <span className='text-green-500'>
                            <Icon path={mdiFileCheck} size={1} />
                        </span>
                        <h2 className='text-2xl font-medium'>{t('active_contracts')}</h2>
                    </header>

                    <p className='text-sm font-light text-gray-500'>{t('no_active_contracts')}</p>
                    {/* <div className='grid gap-x-6 gap-y-4'> */}
                    {/* {eans.map((ean, i) => (
                        <Link className='col-span-12 lg:col-span-6 block max-w-md lg:max-w-xl' key={i} to={`/eans/${ean._id}`}>
                            <EanInfoCard {...ean} />
                        </Link>
                    ))} */}
                    {/* </div> */}
                </Card>

                <Card contentClasses='space-y-6'>
                    <header className='flex items-center space-x-2'>
                        <span className='text-red-500'>
                            <Icon path={mdiFileRemove} size={1} />
                        </span>
                        <h2 className='text-2xl font-medium'>{t('expired_contracts')}</h2>
                    </header>

                    <p className='text-sm font-light text-gray-500'>{t('no_expired_contracts')}</p>
                    {/* <div className='grid grid-cols-12 gap-x-10 gap-y-4'> */}
                    {/* <p className='text-sm font-light text-gray-500'>{t('no_expired_contracts')}</p> */}
                    {/* {contracts.map((contract) =>
                            contract.tariffs.map((tariff, i) => (
                                <div className='col-span-12 max-w-md 2xl:col-span-4 2xl:max-w-none' key={i}>
                                    <ContractInfoCard key={i} {...tariff} />
                                </div>
                            )),
                        )} */}
                    {/* // <Link className='col-span-12 lg:col-span-6 block max-w-md lg:max-w-xl' key={i} to={`/eans/${ean._id}`}>
                            //     <EanInfoCard {...ean} />
                            // </Link>
                        ))} */}
                    {/* </div> */}
                </Card>
            </section>
        </Page>
    );
};

export default Overview;
