import React from 'react';
import { useTranslation } from 'react-i18next';

const RadiobuttonGroup = ({ label, direction, containerClasses, name, options, value, onChange }) => {
    const { t } = useTranslation();

    return (
        <div className={containerClasses}>
            {label && <label className='block text-sm font-light tracking-wider text-gray-700'>{t(label)}</label>}
            <div className={`mt-2 flex ${direction === 'vertical' ? 'flex-col space-y-4' : 'flex-row space-x-4'}`}>
                {options.map((option) => (
                    <label key={option.value} htmlFor={option.value} className='flex select-none'>
                        <input type='radio' id={option.value} name={name} value={option.value} checked={value === option.value} onChange={onChange} className='mt-1 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300' />
                        <span className='max-w-md inline-block ml-2 select-none'>{t(option.label)}</span>
                    </label>
                ))}
            </div>
        </div>
    );
};

export default RadiobuttonGroup;
