import apiClient from '../utils/apiClient';

export default {
    getTickets() {
        return apiClient.get(`/tickets`);
    },
    getTicket(id) {
        return apiClient.get(`/tickets/${id}`);
    },
    createTicket(data) {
        return apiClient.post(`/tickets`, data);
    },
    addMessageToTicket(id, data) {
        return apiClient.post(`/tickets/${id}`, data);
    },
};
